import React from 'react'
import styled from 'styled-components'
import { Avatar, Button } from 'antd';
import { UserOutlined,  LogoutOutlined, LoginOutlined } from '@ant-design/icons';
import Fade from 'react-reveal/Fade'
import {  useDispatch, useSelector } from 'react-redux';
import { userAuthSelector , logout} from '../../api/auth'
import Menues from './menu'
import { Link } from 'react-router-dom';


export default function Sidebar({onClick}) {
    const dispatch = useDispatch()

    const {user} = useSelector(userAuthSelector)
    
  const  SignOut = () =>{
         dispatch(logout())
        // firebase.auth().signOut()
        window.location.href = '/login'

    }

    return (
        <SideBarWrap className="container-fluid p-0" >
            <Fade duration="0.1s">
            <div className="row" >
            <Fade left   distance="20px" >
                <div className="col-8 col-sm-2 bg-white navlink p-0">

                      <div className="container-fluid px-4 py-3" style={{backgroundColor:"#f5f5f5"}}>

        <div className="row">

            <div className="col-3">
            <Avatar size={45} icon={<UserOutlined style={{transform:'translateY(-7px)'}} />} />
            </div>

            <div className="col-9 d-flex align-items-center">

             { user &&  <h6 className="text-capitalize"> {user.user_name}</h6> }

            </div>

        </div>


                      </div>

               <Menues/>
            {/* 
                <div className="copyright" >           
                     <p> Copyright &#169; 2021 Piklocal. <br></br>All Rights Reserved</p>
                 </div>  */}
               
            <div className="logout">
                {user ? 
               <Button icon={<LogoutOutlined style={{transform:'translateY(-2px)'}} />} onClick={SignOut} type="link" className="w-100 ">logout</Button> 
            : <Link to="/login">
                <Button type="link" icon={<LoginOutlined style={{transform:"translateY(-2px)"}} />}>
                    login</Button>
                    </Link>
                     }
               </div>

               
            

                </div>
            

                </Fade>
                <div className="col-4 col-sm-10" onClick={onClick}></div>

            </div>
            </Fade>

           
        </SideBarWrap>
    )
}

const SideBarWrap = styled.div`

background-color:rgba(0,0,0,0.6);
position:fixed;
top:0;
left:0;
z-index:22;
width:100%;
height:100vh;
.logout{

    background-color:#f5f5f5;
    position:absolute;
    left:0;
    bottom:0;
    width:100%;


}
.navlink{

    height:100vh;
}

.copyright{
  position:absolute;
  bottom:20px;
  left:10%;
}

`
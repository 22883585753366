import React, {useEffect} from 'react'
import {List, Avatar, Button} from 'antd'
import {  FaHeart, FaMapMarkerAlt,FaUserAlt, FaShoppingBag} from 'react-icons/fa'
import {  LockFilled} from '@ant-design/icons';


import {MdLiveHelp} from 'react-icons/md'
import styled from 'styled-components'
import {   useSelector } from 'react-redux';
import {Link} from 'react-router-dom'
import { userAuthSelector} from '../../api/auth'

export default function Accountmenu() {
  const {user} = useSelector(userAuthSelector)



    const data = [
        {
          title: 'My Orders',
          icon: <FaShoppingBag/>,
          link:`/my-orders/${user && user._id}`
          // link: `/My-Orders/6008101834bd182f40de0699`
        },
        {
          title: 'My Favourite',
          icon: <FaHeart/>, 
          link: `/my-favourite/${user && user._id}`

        },
        {
          title: 'Change Location',
          icon: <FaMapMarkerAlt/>,
          link: '/google-map-address?ischange=true'
        },

        {
          title: 'Change Password',
          icon: <LockFilled />,
          link: `/change-password/${user && user._id}`

        },

        {
          title: 'Profile',
          icon: <FaUserAlt />,
          link: `/edit-profile/${user && user._id}`

        },
        // {
        //   title: 'Chat',
        //   icon: <MdChat/>,
        //   link: '/chat'

        // },
        {
          title: 'Help',
          icon: <MdLiveHelp/>,
          link: '/help'
        },

      ];


    return (
        <AccountWrap >
            <List
    itemLayout="horizontal"
    dataSource={data}
    renderItem={item => (
      <List.Item className="px-3">
        <List.Item.Meta
          avatar={item.icon}
          title={<Link className="text-secandory" to={item.link}>{item.title}</Link>}
        />
      </List.Item>
    )}
  />
        </AccountWrap>
    )
}


const AccountWrap = styled.div`
padding:0.7rem;
svg {

    font-size:1.1rem;
}

h4{

     text-align:left;
}

`
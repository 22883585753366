import React, {useEffect} from 'react';
import AppWrap from './global'
import './App.css';
import 'antd/dist/antd.css'
import Home from './components/home'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import UserLogin from './auth/login/intialpage'
import Password from './auth/login/forgotPassword';
import Store from './components/store'
import Products from './components/store/products'
import PrivateUserRoute from './auth/privateUserRouter'
import 'react-whatsapp-widget/dist/index.css'
import CartView from './components/store/cart'
import Tracking from './components/store/cart/tracking'

import Account from './components/user'
import Favourite from './components/user/favourite'
import MyOrders from './components/user/orders'
import Order from './components/user/orders/products'
import editProfile from './components/user/profile/editProfile'
import editPassword from './components/user/profile/password'

import Search from './components/search'
import Offer from './components/user/offers'
import Combo from './components/store/comboProducts'
import GooglePin from './shared/googlepin';
import Policy from './components/help&support/index';
import Profile from './components/user/profile';

import {fetchUserProfileDetails, userAuthSelector} from './api/auth'
import { useDispatch, useSelector } from 'react-redux'

import useGaTracker from './useGaTracker'


// function emptyCache() {

//   new Promise((resolve, reject)=>{
 
//     try {
   
//      if('caches' in window){
//        caches.keys().then((names) => {
//                // Delete all the cache files
//                names.forEach(name => {
//                    caches.delete(name);
//                })
//            });
     
//            // Makes sure the page reloads. Changes are only visible after you refresh.
//           //  window.location.reload(true);
//        }
   
//        resolve()
      
//     } catch (error) {
     
//        reject()
   
//     }
//   })
  
// }


function App() {

 const dispatch = useDispatch()
 const {token, user, loading} = useSelector(userAuthSelector)

const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });


};

useEffect(() => {
token && dispatch(fetchUserProfileDetails(token))

}, [dispatch, token])
 
useEffect(() => {
  clearCacheData()
}, [])
// useGaTracker();



  return (  
  
  <Router>
    <main className="App">

   <AppWrap/>
   <Route path="/" component={Home} exact/>
   <Route path="/login" component={UserLogin} exact/>
   <Route path="/forgot-password" component={Password} exact/>

      
        <Route path="/google-map-address" component={GooglePin} exact/>

    <Route path="/policy" component={Policy} exact/>

   <Route path="/store/:id" component={Store} exact/>
    <Route path="/products/:id" component={Products} exact/>
    <Route path="/cart" component={CartView} exact/>

    {
      (user && !loading) && <>  <PrivateUserRoute path="/tracking" component={Tracking} exact/>
    <PrivateUserRoute path="/account" component={Account} exact/>
    <PrivateUserRoute path="/my-favourite/:id" component={Favourite} exact/>
    <PrivateUserRoute path="/my-orders/:id" component={MyOrders} exact/>
    <PrivateUserRoute path="/order/:id" component={Order} exact/>
    <PrivateUserRoute path="/profile" component={Profile} exact/> 
    <PrivateUserRoute path="/edit-profile/:id" component={editProfile} exact/> 
     <PrivateUserRoute path="/change-password/:id" component={editPassword} exact/> </>

    }
 
    <Route path="/combo/:id" component={Combo} exact/>
    {/* <PrivateUserRoute path="/chat" component={Chat} exact/> */}
    <Route path="/offers" component={Offer} exact/>
    <Route path="/search" component={Search} exact/>


    </main>
 
    </Router>
  );
}

export default App;




import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { message } from 'antd';
import keyUri from '../key'
import {fetchCurrentOrder} from './placeOrder'

const initialState = {

    near_stores:[],
    all_stores:[],
    current_store:null,
    loading:false,
    hasError:false,
    currentStoreCatagories:[],
    currentStoreProducts:[],
    currentCatagoryProducts:[],
    currentCategory:null,
    
    offer:null,
    allOffers:[],

    combo:null,
    allCombo:[],
    comboProducts:[],

}


export const storeSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {

    getStores: (state) => {
      state.loading = true;
    },

    
    getNear_stores_Success: (state, {payload}) => {

      state.loading = false
      state.near_stores = payload
    },

      getCurrentSuccess: (state, {payload}) =>{
        state.loading = false
        state.current_store = payload.store
        state.currentStoreCatagories = payload.categories
        state.currentStoreProducts = payload.products
      },

      getCurrentCatagoryProducts: (state, {payload})=>{
        state.loading = false
      state.currentCatagoryProducts = payload.products
      state.currentCategory = payload
      },

      get_stores_Failure: (state) => {

            state.loading = false
            state.hasError = true
          },


      getOffer: (state,payload) => {
        state.loading = true;
        // state.alloffers = payload
      },

      getCurrentOffer:(state, {payload}) => {
        console.log(payload);
        state.loading = false
        state.offer = payload.offer
      },

      getOfferSuccess: (state, {payload}) => {
        state.loading = false
        state.allOffers = payload
      },


      getCombo: (state, {payload})=>{
        state.loading = false
        },

        getCurrentCombo:(state, {payload}) => {
          state.loading = false
          state.combo = payload.combo
          state.comboProducts = payload.combo.combo_product
        },
  
        getComboSuccess: (state, {payload}) => {
          state.loading = false
          state.allCombo = payload
        },
  },
})


export const { getStores , getOffer,getCombo,getCurrentCombo,getComboSuccess,  getCurrentCatagoryProducts,getOfferSuccess,getCurrentOffer,  getCurrentSuccess, getNear_stores_Success, get_stores_Failure } = storeSlice.actions;

export const storesSelector = state => state.stores;


const config = {
  headers: {
      Accept: "application/json",
  }
};

export const fetchNearStores = (location) => async dispatch => {
  dispatch(getStores())





 const loc = {
  latitude: location.lat,
  longitude: location.lng,

 }
 try {

  const {data} = await axios.post(keyUri.BACKEND_URI +'/store', loc, config)

  
  dispatch(getNear_stores_Success(data.store));
   
 } catch (error) {

dispatch(get_stores_Failure())

   
 }
}

 export const deleteStore = (id) => async dispatch => {

  dispatch(getStores())
  const key = 'create';
  message.loading({ content: 'loading...', key })
  try {
 
   const {data} = await axios.delete(keyUri.BACKEND_URI +`/store/${id}`)
  data && message.success({ content: data.msg, key, duration: 2 });
   dispatch(fetchNearStores());
    
  } catch (error) {

    console.log(error);
 dispatch(get_stores_Failure())
 
  }
 };


 export const fetchOneStore = (id) => async dispatch => {
  try {
   const {data} = await axios.get(keyUri.BACKEND_URI +`/current-store/${id}`)

   dispatch(getCurrentSuccess(data));
  } catch (error) {

 dispatch(get_stores_Failure())
  }
 };


 export const fetchOneCategory = (id) => async dispatch => {
  dispatch(getStores())
  try {
   const {data} = await axios.get(keyUri.BACKEND_URI +`/current-category/${id}`)
  
   dispatch(getCurrentCatagoryProducts(data));
  } catch (error) {


 dispatch(get_stores_Failure())
  }
 };



   
 export const fetchOffer = () => async dispatch => {
 
  dispatch(getOffer())
 try {
  const {data} = await axios.get(keyUri.BACKEND_URI+`/all-offers`)
  dispatch(getOfferSuccess(data));
   
 } catch (error) {
dispatch(get_stores_Failure())
 }
}


export const fetchCurrentOffer = (id) => async dispatch => {
  
    dispatch(getOffer())
    try {
     const {data} = await axios.get(keyUri.BACKEND_URI +`/offer/${id}`)
  
     dispatch(getCurrentOffer(data));
    } catch (error) {
   dispatch(get_stores_Failure())
    }
   };
  


    
 export const fetchCombo = (id) => async dispatch => {
 
  dispatch(getCombo())
 try {
  const {data} = await axios.get(keyUri.BACKEND_URI+`/combos/${id}`)
  dispatch(getComboSuccess(data));
   
 } catch (error) {
dispatch(get_stores_Failure())
 }
}


export const fetchCurrentCombo = (id) => async dispatch => {
    dispatch(getCombo())
    try {
     const {data} = await axios.get(keyUri.BACKEND_URI +`/combo/${id}`)
  
     dispatch(getCurrentCombo(data));
    } catch (error) {
   dispatch(get_stores_Failure())
    }
   };
  


 export const  updateStorerating = (id, values, order) => async dispatch =>{

const rate = {
  rating:values,
  order:order
 }
  const key = "store"
  dispatch(getStores())
  message.loading({ content: 'loading...', key })
try {
    const {data} = await axios.put(keyUri.BACKEND_URI + `/store-rating/${id}`, rate, config);
    data && message.success({ content: data.msg, key, duration: 2 });
    dispatch(fetchCurrentOrder(order))
} 


catch ({response}) {
    dispatch(get_stores_Failure())
    response.data && message.error({ content: response.data.msg, key, duration: 2 });

}
}






export default storeSlice.reducer;

import React, {useState, useEffect} from 'react'
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import {  useSelector } from 'react-redux';
import { userAuthSelector} from '../../api/auth'
import {useLocation} from 'react-router-dom'


import {
  LockFilled,
  GiftFilled,
  QuestionCircleFilled,
  HomeFilled,
  ShoppingFilled,
  FileTextFilled
} from '@ant-design/icons';



export default function MenuBar() {

  const {pathname} = useLocation()

  const {user} = useSelector(userAuthSelector)  
  const [current, setCurrent] = useState()

  useEffect(()=>{

  
    setCurrent( pathname )

}, [pathname])


const handleClick = e => {
   
    
  setCurrent( e.key )
};



    return (
        <div>
            <Menu
      
      selectedKeys={[current]}
      
        mode='inline'
   
      >
        <Menu.Item key="/" onClick={handleClick} icon={<HomeFilled />}>
        <Link to="/">Home</Link>
        </Menu.Item>

        <Menu.Item key="/cart" onClick={handleClick} icon={<ShoppingFilled />}>
        <Link to="/cart"> My Cart</Link>
        </Menu.Item>

        
        <Menu.Item key="/offers" onClick={handleClick} icon={<GiftFilled />}>
            <Link to="/offers">Offers </Link>
        </Menu.Item>

       { user && <Menu.Item key="/my-orders" onClick={handleClick} icon={<FileTextFilled />}>
          <Link to={`/my-orders/${user && user._id}`}> My Order</Link>
        </Menu.Item>
 }
    
     { user &&     <Menu.Item key="/account" onClick={handleClick} icon={<LockFilled />}>
           <Link  to='/account'> My Account</Link>
        </Menu.Item>
    }
        <Menu.Item key="/policy" onClick={handleClick} icon={<QuestionCircleFilled />}>
        <Link  to='/policy'> Help & Support</Link>
        </Menu.Item>
        
      </Menu>
        </div>
    )
}

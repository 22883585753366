import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import keyUri from '../../../key'
import {Link, useParams} from 'react-router-dom'
import {Row, Col} from 'antd'
import axios from 'axios';
import key from '../../../key';
import Loader from '../../../shared/loader'


import {AiFillLeftCircle, AiFillRightCircle} from 'react-icons/ai'
export default function Catagory() {

    const { id } = useParams()
const [catagories, setCategories] = useState([])
const [loading, setLoading] = useState(false)


useEffect(() => {
    setLoading(true)
    axios.get(key.BACKEND_URI + `/current-store/${id}?category=category`).then(({data})=>{

        setCategories(data)
        setLoading(false)
    })

  }, [id])

    const settings = {
        dots: false,
        infinite: true,
        arrows:false,
        speed: 500,
        slidesToShow: (catagories.length > 3) ? 4 : (catagories.length > 1) ? 2 : 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true
      };

  



// const {id} = useParams()
const sliderRef = React.useRef(Slider);

    return (
        <CatgoryWrap  style={{width:"100%", overflowX:"hidden"}} className="mx-auto my-4">
 {   loading ? <Loader/> :    <>
<h4 className="p-2 mb-4">Shop by Catagory</h4>

<Row>
    <Col span={2} className="d-flex align-items-center justify-content-center">

    <AiFillLeftCircle className="ic" onClick={()=>sliderRef.current.slickPrev()} />

    </Col>
 <Col span={20} >
<Slider {...settings}   ref={sliderRef}>
    {
        catagories.map((item, i)=>{
          return <Link key={i}  to={`/products/${item._id}`}>
           <div  className="my-3" >

    <img src={keyUri.BACKEND_URI+`/category-image/${item.category_image}`} alt="fgfg" style={{objectFit:'contain'}}
    className="mx-auto d-block  shadow-sm"/>       
    <p className="d-block text-center py-2" >{item.category_name}</p>

        </div> 
        </Link>  
        })
    }
    </Slider>
    </Col>
    <Col span={2} className="d-flex align-items-center justify-content-center">
    <AiFillRightCircle className="ic" onClick={()=>sliderRef.current.slickNext()}/>
    </Col>
    </Row>
    </>}
        </CatgoryWrap>
    )
}
const CatgoryWrap = styled.div`


.ic{

    font-size:1.5rem;
   cursor: pointer;
   color:#d8d8d8;
   transform:translateY(-2rem);
   transition:ease-in-out 0.1s;

   &:hover{

       color:grey;
       font-size:1.6rem;
   }
}
img {

width: 150px;
    height: 150px;
    padding: 0.5rem;

}
p{
    font-size:17px;
}
`
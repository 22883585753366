import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import keyUri from '../key'

const cart =  localStorage.getItem('cart') ?
   JSON.parse(localStorage.getItem('cart')) : []


let total = cart.reduce((total, num)=>{

    return total + (num.sale_price * num.units)

}, 0)
   

export const initialState = {

    loading: false,
    hasErrors: false,
    cartCoupons:cart,
    subTotal:0,
    total:  total 
}

export const cartSlice = createSlice({
    name:"cart",
    initialState,
    reducers:{

        getCartCoupons: state =>{

            state.loading = true
        },
        
      getTotal: state =>{
         
        state.total = state.cartCoupons.reduce((total, crr)=>{

            return total + (crr.sale_price * crr.units)

        }, 0)

      },
      removeItem: (state, {payload}) => {

        state.cartCoupons = state.cartCoupons.filter(item => {

            return item._id !== payload
        }) 
        state.total = state.cartCoupons.reduce((total, crr)=>{

            return total + (crr.value * crr.units)

        }, 0)

        localStorage.setItem('cart', JSON.stringify(state.cartCoupons) )
       

      },
        getCartCouponSuccess : (state, {payload}) =>{
         

       const exist = state.cartCoupons.findIndex((item) => item._id === payload._id);

       if(exist !== -1){

     state.cartCoupons[exist].units = state.cartCoupons[exist].units + 1

}else{

     state.cartCoupons =[...state.cartCoupons, payload] 

}
       

            state.loading = false
            state.hasErrors = false
            state.total = state.cartCoupons.reduce((total, crr)=>{

                
                return total + (crr.sale_price * crr.units)
    
            }, 0)

            
            
            localStorage.setItem('cart', JSON.stringify(state.cartCoupons) )


        },
        increments:( state, {payload}) =>{

   
          state.cartCoupons.map(item=>{

                if(item._id === payload.id && payload.incr === "incr" ){
                   
                    if(item.units < item.stock) {

                        item.units += 1
                    } 
                    else
                    {
                       message.info('out of stock')
                    }
                   
                  
               
                } else if(item._id === payload.id && payload.decr === "decr"){
                    item.units -= 1; 
                   
            

                }

                return state.cartCoupons
               
            })

        },
      
        getCartCouponsFailure : state =>{

            state.loading = false
            state.hasErrors = true 
        }

    }
})

export const {
    getCartCouponsFailure,
    getCartCouponSuccess,
    getCartCoupons,
    increments,
    getTotal,
    removeItem
} = cartSlice.actions

export const cartCouponSelector = state => state.cart
export default cartSlice.reducer



export const addCartCoupons = (data) => async dispatch =>{

    dispatch(getCartCoupons())
    try {
        dispatch(getCartCouponSuccess(data))  
    } catch (error) {
        dispatch(getCartCouponsFailure())
  
    }
}


export const qtyupdate = (id) => async dispatch =>{

    dispatch(increments(id))
    dispatch(getTotal())

}

export const deleteCartItem = (id) => async dispatch =>{

    dispatch(removeItem(id))
}

export const clearCartData = (data) => async dispatch =>{

    localStorage.removeItem('cart')

    window.location.reload()


}

import React, {useEffect} from 'react'
import { Button } from 'antd';
import { Link } from 'react-router-dom'
import {useSelector} from 'react-redux'
import { userAuthSelector } from '../../../api/auth';

export default function Profile() {

// const user =   localStorage.getItem('user_data') ? 
//    JSON.parse(localStorage.getItem('user_data')) : null  

const {user} = useSelector(userAuthSelector)
console.log(user);
    return (
        <>
    {


   user && <div className="container mt-5">
<br/>
<div>
    <h6 className="text-left">Change Address</h6>
</div>
<br/>
<div className="text-left">
<p><b>Building Name</b> :{user.address.building_name}</p>
<p><b>Door Number</b> : { user.address.door_number}</p>

<p>
<b>Address: </b>
{user.address.defaultAddress}
   
</p></div>
       
           <Link to="/google-map-address?ischange=true">
     <Button className="ml-2 mb-3" 
     style={{  color: "white" }}
     size="small" type="primary" >Change Address</Button>
</Link>
            
        </div>
         } 
        </>
    )
}

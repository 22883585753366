const limitDesc = (str, size=140, moreread) =>{

    console.log(str);

    if(moreread){

        return str
    }

    let more = str.length > size ? '....' : ''
    
    
        return str.slice(0, size) + more;
    }
    
    export default limitDesc
import React from 'react'
import { Row, Col } from 'antd'
import keyUri from '../../key'
import bannerimg from '../../images/banner.jpg'
import styled from 'styled-components'
export default function Banner({storeinfo}) {
 
    return (
        <>
      {

storeinfo &&   <DBWrap  style={{backgroundImage:`url(${keyUri.BACKEND_URI || bannerimg}/store-image/${storeinfo.image})`}}>
        <div className="ban"  style={{ zIndex:"22"}}>
            <Row align="middle" className="container pt-4">
                <Col>
                <div className="px-2 py-3 text-left">
                <h5 className="mb-1 text-white text-capitalize" >{storeinfo.store_name}</h5>
                <p className="text-light">{storeinfo.store_address} </p>
                </div>

                {/* <img className="rounded" src={storeImgUri.BACKEND_IMAGE_API + `/${storeinfo.image}`} alt="storeimg" alt="storeimage" height="150px" width="100%" /> */}
      
                {/* <img className="rounded" src={`https://d1jie5o4kjowzg.cloudfront.net/deli-headline-banner-images/meijer_smallstore_deli_060717.jpg`} alt="storeimage" height="150px" width="100%"/> */}
                
                </Col>
            </Row>

           
        </div>
        </DBWrap> }
        </>
    )
}


const DBWrap =  styled.div`
margin-bottom:1rem;
height:150px;
background-position:center;

position: relative;


.ban {
position: relative;


}
    &::after {

    position: absolute;
    width: 100%;
    content:"";
    top:0;
    left:0;
    background-color: #2929299e;
    height:100%;
}

`
import React, {useState, useEffect} from 'react'
import {Row, Col, Image,  message, Drawer } from 'antd'
import styled from 'styled-components'

import keyUri from '../../../key'
import loadingImage from '../../../images/load2.gif'
import limitDesc from '../../../shared/limitDesc'
import {useMedia} from 'react-use';
import axios from 'axios'



export default function Offers({data}) {


    const isWide = useMedia('(min-width: 480px)');


    const onClick = (e, item)=>{
        console.log(item)
        axios.get(keyUri.BACKEND_URI + `/offer-views/${item._id}`)        
      }
      

    return (
        <ProductWrap className="px-3 ">
            {
        data.map((item, i)=>{
            
            
             return (item.offer_theme !== 'text') ? <a key={i} target={item.link ? '_tab': null} href={item.link ? item.link : `/products/${item.category}`}  onClick={(e)=>onClick(e, item)}>

                <Row key={i} style={{borderRadius:"5px", height:isWide? "280px" : "120px", backgroundColor: (item.offer_theme === 'blue') ? "rgb(234 243 255)" : "rgb(255 228 228)", padding:"5px 0 5px 0"}} className="my-4 text-left shadow-sm product" gutter={20}>
                
                   <Col span={7} >
                       <div className="imageclass" style={{height:"100px"}}>
                       <Image
                            height="100%"
                            src={keyUri.BACKEND_URI+`/offer-image/${item.offer_image}`}

                            placeholder={
                            <Image
                                preview={false}
                                src={loadingImage}
                                width={ 100}
                                height="100%"
                                        />
                            }
                        />
                  </div> 
                  </Col>
                    <Col span={13}>
                    <h6 className="text-left my-0 store text-capitalize">{item.store.store_name}</h6> 
                    <h5 className="text-left my-0 title text-capitalize">{item.offer_title}</h5> 
                    <h6 className="text-left mb-0 des" style={{paddingRight:'1px'}}>{
                   limitDesc(item.offer_Discription, 40) }</h6>
              
                    <button className="sbtn" style={{backgroundColor: (item.offer_theme === 'blue') ? null : 'rgb(230 57 57)'}}>Offer Price</button>
                     <small className="ml-2 price"><del>&#x20B9;{item.current_price}</del></small>
                     <b className="ml-2 price">&#x20B9;{parseInt((item.current_price*((100- item.offer)/(100))))}</b>
                   
                    </Col>
                   { item.offer && <Col span={3}>
                         <div className="offer d-flex justify-content-center" style={{backgroundColor: (item.offer_theme === 'blue') ? null : 'rgb(226 61 61)'}} >
                        <p >{item.offer}%</p>
                        </div>
              
                        
                    </Col>}

                </Row>
                </a> 
                
            : <a key={i} target={item.link ? '_tab': null} href={item.link ? item.link : `/store/${item.store._id}`}>

                 <Row key={i} style={{borderRadius:"5px", backgroundColor: "rgb(255 236 198)" }} className="my-4 py-3 px-3 text-left shadow-sm product" gutter={20}>
                 
                    
                     <Col span={24}>
                     <h6 className="text-left my-0 store text-capitalize">{item.store.store_name}</h6> 
                     <h5 className="text-left mb-0 title">{item.offer_title}</h5> 
                     <h6 className="text-left mb-0 des" style={{paddingRight:'1px'}}>{item.offer_Discription}</h6>
     
                     </Col>
        
 
                 </Row>
                 </a>  
               })
            }
          
        </ProductWrap>
    )
}


const ProductWrap =  styled.div`

.sbtn{
    padding: 1px 5px;
    background-color:#3e91f7;
    color:white;
    border:none;
    border-radius:4px;
    margin-top:0.5rem;
    font-size:10px;
}

.link{
    font-size:10px;
}

.store{
    font-size:14px;
    padding-bottom:4px;
    color: #383838;
}

.des{
    font-size:14px;
    padding-bottom:2px;

}

.title{
    font-size:17px;
    font-weight:700;
    padding-bottom:5px;
}

.price{
    color:black;
}

.product{
position:relative;
  .offer{
  width:50px;
  height:50px;
  background-color:#3e91f7;
  position:absolute;
  top:5%;
  right:5%;
  margin:0 ; 
  color:white;
  clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%);

  p{
    margin-top:0.5rem;
    font-size:20px;
    font-weight:900;
    
  }
}
}

.imageclass{
  position:relative;
}

`
import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {userAuthSelector} from '../../api/auth'
import {Avatar, Col, Row} from 'antd'
import ListMenu from './accountmenu'
import Footer from '../../global/footer'
import styled from 'styled-components'
import {useMedia} from 'react-use';

export default function UserIndex({history}) {

const {user, loading} = useSelector(userAuthSelector)
const isWide = useMedia('(min-width: 480px)');


    useEffect(() => {
    
        if(!user && !loading) {


         return  history.push('/account')
        } else {

                history.push('/account')
            
        }



    }, [user, history])


    return (
        <CartWrap >
        <div className="px-3 py-2 bg-light text-dark ">
            
         {
             user && <Row justify="space-between">

 
             <Col className="text-left">
             <h2 className="mb-2">{user.user_name}</h2>
             <b>+91 {user.phone_number} </b>
            
             </Col>

             <Col>
             
             <div className="rounded-circle d-flex align-items-center justify-content-center" style={{   padding: "0 0.7rem "}} >
{       isWide ? "" :  <h1 className="text-uppercase text-danger mb-0">{user.user_name.charAt(0)}</h1>
}                 </div>
             
             </Col>
             <div className="text-left">
            { user.address &&  <p>{user.address.building_name}, {user.address.door_number}, {user.address.defaultAddress}</p>}
             </div>

             </Row>
         }
        </div>
<ListMenu/>
  <Footer/> 
</CartWrap>
    )
}



const CartWrap = styled.div`

padding-top: 40px;


`
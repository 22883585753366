import React, {useState, useEffect} from 'react'
import Navbar from './navbar'
import Sidebar from './sidebar'
import { useLocation } from 'react-router-dom'


export default function IndexNavbar() {

    const {pathname} = useLocation()

    const [open, setOpen] = useState(false)


    useEffect(() => {
        setTimeout(()=>setOpen(false) , 100)
     
    }, [pathname])



    const onClick = () =>{
        setOpen(!open)

    }

    return (
        <div >
            {(pathname !== '/login' && pathname !== '/google-map-address') && <Navbar onClick={onClick} open={open} />}
            
        {
            open && <Sidebar onClick={onClick} />
        }
            

        </div >
    )
}

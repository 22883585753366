import React from 'react'
import {Row, Col, Button} from 'antd'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import keyUri from '../../key'
import {StarFilled, ShareAltOutlined } from '@ant-design/icons';
import { Typography } from 'antd'
import axios from 'axios'
const {Paragraph} = Typography


export default function Stores({store}) {


    if(store.length < 1) {

     return   <h5 className="py-5 text-center text-secondary">No stores Available</h5>
    }


    const onClick = (e, item)=>{
        console.log(item)
        axios.get(keyUri.BACKEND_URI + `/update-views/${item._id}`)
    }
      

    return (
        <StoreWrap className="container   my-3">

        {

 store.map((item, i)=>{

  

    let ratings=  item.rating.reduce((total, current)=>{
        return total+current
    }, 0)

    let rate = (ratings/(item.rating.length )).toFixed(1)

  
             return <div key={i}   className="shadow-sm my-3 py-0 rounded">
        <Link to={`/store/${item._id}`} onClick={(e)=>onClick(e, item)} >
               <Row gutter={12}>
                   <Col span={8}>         

                <img className="shadow-sm h-100" src={keyUri.BACKEND_URI+`/store-image/${item.image}`} alt="storeimg" width="100%"/>

                       </Col>
                  <Col className="text-left" span={16}>
                      <div className="d-flex align-items-center justify-content-between">
                <h6>{item.store_name  }</h6>
                 <Paragraph copyable={{text:`https://stores.piklocal.com/store/${item._id}`,
                icon:<ShareAltOutlined style={{color:"#01C0CF"}}/>
                }}/>

                </div>
                <hr/>
               <Row>
                  <Col className="text-left" style={{color:'black'}} span={24}>
              
                <p className="mb-0">{item.locality}</p>

                  </Col>

                  <Col  span={24} >
                   <Button size="small" className="my-1 mx-2 shadow-sm px-2 py-0 rounded float-right" type="primary" style={{backgroundColor: rate>3.5 ? '#3e8e3e' : rate>2.5 ? '#e4c12a' : '#d83f3f' , border:'none'}} >
                            <p className="mb-0"><StarFilled style={{ transform:'translateY(-4.5px)', marginRight:'2px'}}/><b>{ isNaN(rate)? 0 : rate }</b>  ({item.rating.length })</p> 
                       </Button>


                  </Col>

                          
                      </Row>
         
                   </Col>
                
               </Row>
        </Link>   

             </div>


            }) 
        }
                   
        </StoreWrap>
    )
}


const StoreWrap =  styled.div`

img{

    border-radius:5px;
}

hr{

    margin:0;
}

`



import React from 'react'
import { Row, Col } from 'antd'
import keyUri from '../../../key'
import bannerimg from '../../../images/banner.jpg'
import styled from 'styled-components'
export default function Banner({storeinfo}) {
 
    return (
        <>
      {

storeinfo &&   <DBWrap  style={{backgroundImage:`url('${bannerimg}')`}}>
        <div style={{backgroundImage:`url(${keyUri.BACKEND_URI}/store-image/${storeinfo.image})`}}>
            <Row align="middle" className="container pt-5">
                <Col>
                <div className="px-2 py-3 text-left">
                <h1 className="mb-1 text-capitalize" >{storeinfo.store_name}</h1>
                <h6 className="text-secondary">{storeinfo.store_address}</h6>
                </div>
           
                </Col>
            </Row>

           
        </div>
        </DBWrap> }
        </>
    )
}


const DBWrap =  styled.div`
margin-bottom:2rem;
height:200px;
background-position:center;

`
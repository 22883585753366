import React  from 'react';
import { Route, Redirect } from 'react-router-dom';
import {userAuthSelector} from '../api/auth'
import {useSelector} from 'react-redux'
const PrivateRoute = ({ component: Component, ...rest }) => {

const {loading, user} = useSelector(userAuthSelector)



  return (
    <Route
      {...rest}
      render={props =>
       
        !user && !loading  ? (
        
          <Redirect to='/login' />
        ) : (
          <Component {...props} />
         
        )
      }
    />
  );
};

export default PrivateRoute;



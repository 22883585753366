import React, { useEffect } from 'react'
import Banner from '../../../shared/banner'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {fetchOneCategory, fetchOneStore, storesSelector} from '../../../api/stores'
import {fetchFavourite, userAuthSelector} from '../../../api/auth'

import Loader from '../../../shared/loader'
import Product from '../../store/products/product'
import Footer from '../../../global/footer'
export default function FavouriteIndex() {

const {id} = useParams()
const dispatch = useDispatch()
const {currentCategory,  loading} = useSelector(storesSelector)


const {user, favourites } = useSelector(userAuthSelector)

//     useEffect(()=>{
// console.log("testing");
//         // dispatch(fetchFavourite(id))

//     }, [dispatch])

console.log(favourites);

    return ( <>
      { 
         
      user && <div className="px-2 pb-5 pt-5">
            <h6>My Favourite</h6>
            {
                loading ? <Loader/> : <Product storeid={currentCategory && currentCategory.store}
                 products={favourites}  />           
            }
            
            <Footer/>  

        </div>
    }

        </>
    )
}





import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import RProduct from './products/rproducts'

export default function Rproducts({products}) {

    const [recommendedProduct, setRecommendedProduct] = useState([])  

    useEffect(()=>{
       const rp = products && products.filter((item)=>{
           return item.isRecommended 
       })
       setRecommendedProduct(rp) 
        }, [products])


    return (
        <>
    {recommendedProduct && <RWrap className="pb-0">

        <RProduct products={recommendedProduct} />

    </RWrap>} </>
    )
}

const RWrap = styled.div`



`
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import { Steps } from 'antd'
import SocketUri from '../../../key'
import socketIOClient from 'socket.io-client'
import {useDispatch, useSelector} from 'react-redux'
import { orderSelector,fetchCurrentOrder, fetchOrderTracking} from '../../../api/placeOrder'
import {fetchUserDetails, userAuthSelector} from '../../../api/auth'

import {message} from 'antd'
import moment from 'moment'
const { Step } = Steps;



export default function Tracking() {

    const dispatch = useDispatch()


    const {user} = useSelector(userAuthSelector)
    const { tracking, dataState, order} = useSelector(orderSelector)
   
    const socket =  socketIOClient(SocketUri.SOCKET_URI, { transports: ['websocket', 'polling', 'flashsocket'] });
    useEffect(()=>{
        

  
      
        user &&  socket.on(user._id, (data) => {
    
            message.info('order status updated')
       
            dispatch(fetchOrderTracking(data))
            dispatch(fetchCurrentOrder(order._id))
    
           });
    }, [])




let timestamp = moment(order.updatedAt).format('MM Do YY, h:mm')



    return (
       
        (user && order) && <TrackWrap>
        {

order.orderStatus === 'Reject' && <>
<h4 className="text-danger ">Your Order Rjected</h4> 
<p >{order.reject_reason}</p>
 </>
        }
        {

    order.orderStatus !== 'Reject' &&   <Steps direction="vertical" status={ order.orderStatus === 'Reject'? "error" : "finish"} size="small" current={dataState} >
    <Step title="Submitted" description="Your Order submited " />

    <Step title={dataState === 1 ? "Accepted" : dataState === -1 ? 'Rejected' : 'Accepted'} 
    description={dataState >= 1  ? `accepted ${(dataState === 1) ? timestamp : ''}` : dataState === -1 ? order.reject_reason : 'processing' } />

    <Step  disabled={dataState === -1 ? true : false}
     title={dataState >= 2 ? "Packed" : "packing"}
      description={dataState === 2 ? `Packed at ${(dataState === 2) ? timestamp : ''}` : "proccing"} />
 
     <Step disabled={dataState === -1 ? true : false} title={dataState >= 3  ? order.orderStatus : "proccing"}
     description={`Your Order is  ${order.orderStatus} ${(dataState === 3)? timestamp :''}`} />

      <Step disabled={dataState === -1 ? true : false} title={dataState === 4  ? "Delivered" : ""} description="Your Order is Delivered" />

 </Steps>
 
            
}

        </TrackWrap>
    )
}

const TrackWrap = styled.div`

`

import React , { useEffect } from 'react'
import { Steps } from 'antd';
import Footer from '../../../global/footer'
import { CheckCircleOutlined} from  '@ant-design/icons'
import { orderSelector, fetchOrderTracking, fetchCurrentOrder } from '../../../api/placeOrder'
import {useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import {useParams} from 'react-router-dom'
import {userAuthSelector, fetchUserDetails} from '../../../api/auth'
import SocketUri from '../../../key'
import socketIOClient from 'socket.io-client'
import {message} from 'antd'

const { Step } = Steps;


export default function Tracking() {

    const {allOrder,isOrderPlaced, order } = useSelector(orderSelector)
    const { user} = useSelector(userAuthSelector)

  
    const {id} = useParams()

    const dispatch = useDispatch()
    // let token = localStorage.getItem('pclienttoken')
    let user_data = localStorage.getItem('user_data') ? JSON.parse(  localStorage.getItem('user_data')) : null

    useEffect(()=>{
        

        dispatch(fetchCurrentOrder(id))
        user_data && dispatch(fetchUserDetails(user_data._id))

    }, [dispatch])

    const socket =  socketIOClient(SocketUri.SOCKET_URI, { transports: ['websocket', 'polling', 'flashsocket'] });



useEffect(()=>{

 
    socket.on( user._id, (data) => {

        message.info('order status updated')
   
        // dispatch(fetchOrderTracking(data))

       });
}, [])
    return (
        <>
           <div style={{fontSize:'80px', color:'#89e07a'}}><CheckCircleOutlined /></div>
           <p>Tracking Details</p>

           <div className="d-flex mt-5 mb-3 pl-4 text-left">
            <h6>Order ID: {allOrder.order.orderId} </h6>
            </div>

            <div style={{paddingRight:'100px'}} >       
      
            <Steps direction="vertical" current={1}>
    <Step title="Finished" description="This is a description." />
    <Step title="In Progress" description="This is a description." />
    <Step title="Waiting" description="This is a description." />
  </Steps>


            </div>
            
           
            
 

            <Footer/>  
        </>
    )
}

import React, {useEffect, useState} from 'react'
import {cartCouponSelector} from '../api/cart'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import styled from 'styled-components'
import { Badge } from 'antd';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import {FaUserAlt, FaHome, FaGift, FaSearch, FaShoppingBag} from 'react-icons/fa'

export default function Footer() {

    const {pathname} = useLocation()
    const {cartCoupons } = useSelector(cartCouponSelector)
const [current, setCurrent] = useState()


useEffect(()=>{

  
    setCurrent( pathname )

}, [pathname])

const handleClick = e => {
   
    
    setCurrent( e.key )
  };


    return (
        <FooterWrap className="container-fluid footer">

<Menu  selectedKeys={[current]} mode="horizontal">


<Menu.Item key="/" onClick={handleClick} >
<Link to="/"><FaHome className="menuicon"/></Link>
    </Menu.Item>


 <Menu.Item key="/search" onClick={handleClick} >

<Link to="/search"><FaSearch className="menuicon" /></Link>

     </Menu.Item>



 <Menu.Item key="/offers" onClick={handleClick} >

<Link to="/offers"><FaGift className="menuicon" /></Link>
  
 </Menu.Item>



 <Menu.Item key="/cart" onClick={handleClick} >
 
<Link to={`/cart?id=${cartCoupons[0]?.store}`}><FaShoppingBag className="menuicon"/>
 <Badge count={cartCoupons.length}  style={{ backgroundColor: '##e22a2afa' }}  offset={[-5, -8]}>
      <span color="green" className="head-example" />
    </Badge></Link>

     </Menu.Item>

 

 <Menu.Item key="/account" onClick={handleClick} >

 <Link  to='/account'><FaUserAlt className="menuicon" /></Link>

 </Menu.Item>
    </Menu>


        </FooterWrap>
    )
}


const FooterWrap = styled.div`

position:fixed;
bottom:0;
left:0;
width:100%;
background-color: #ffffff;
    box-shadow: 0 -1px 5px 0px #b1b1b1;

    .menuicon {

font-size:2rem;
text-align:center;
padding: 0.4rem;
margin:0;

}
.ant-menu-horizontal{

    display:flex;
    justify-content:space-around;
}
.ant-menu-horizontal > .ant-menu-item {

 
    margin:0 0.8rem;
    

}

.ant-menu-horizontal > .ant-menu-item a {
    color:grey;
}

.ant-menu-horizontal > .ant-menu-item-selected
{
 a {
    color:#3598DB !important;
}
    border-bottom: 2px solid #3598DB;
}



.ant-badge-count {
    z-index: auto;
    min-width: 17px;
    height: 17px;
    padding: 0 4px ;
    color: #f9f9f9;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    white-space: nowrap;
    text-align: center;
    background: #ff4d4f;
    font-weight: bold;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 0 1px #fff;
    box-shadow: 0 0 0 1px #fff;
}





`
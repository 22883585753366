const key = {
    BACKEND_URI :"https://piklocal-server-yr7b5.ondigitalocean.app/api",
    // BACKEND_URI :"http://localhost:5000/api",

    

    // SOCKET_URI:"http://localhost:5000",
        SOCKET_URI:"https://piklocal-server-yr7b5.ondigitalocean.app",



        // BACKEND_IMAGE_API : "http://localhost:5000/static/images"
        BACKEND_IMAGE_API : "https://piklocal-server-yr7b5.ondigitalocean.app/static/images"

}

export default key
import React from 'react'
import styled from 'styled-components'
import bannerimg from '../images/banner.jpg'
export default function Banner({title}) {

    console.log(title);
    return (
        <BannerWrap
        className="bg-light"
        style={{backgroundImage:`url('${bannerimg}')`}}>
      <div className="container d-flex align-items-center h-100">

         <h2>{title}</h2>  
      </div>
     
            {/* <img src="https://149473553.v2.pressablecdn.com/wp-content/uploads/2020/05/Grocery-banner-may30.png" alt="banner" width="100%"/> */}
        </BannerWrap>
    )
}


const BannerWrap = styled.div`

/* background-image:url(''); */
height:150px;
background-position:center;
h2{

    text-align:left;
    text-transform:capitalize;
    font-size:2.5rem;


    transform:translateY(0.5rem);
}

`

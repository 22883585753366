import React, { useEffect } from 'react'
import Banner from '../../../shared/banner'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {fetchCurrentCombo, fetchOneStore, storesSelector} from '../../../api/stores'
import Loader from '../../../shared/loader'
import Product from './product'
import Footer from '../../../global/footer'
import {fetchUserProfileDetails, userAuthSelector} from '../../../api/auth'


export default function StoreIndex() {

    const {id} = useParams()
const dispatch = useDispatch()
const {currentCategory,comboProducts,combo, current_store, currentCatagoryProducts, loading} = useSelector(storesSelector)
const {token, user} = useSelector(userAuthSelector)

    useEffect(()=>{
        dispatch(fetchCurrentCombo(id))
        !user && dispatch(fetchUserProfileDetails(token))

    }, [dispatch])






    console.log(comboProducts[0]?.store);
    return (
        <>
       {user && <div className="px-2 pb-5 pt-5">
            {

                loading ? <Loader/> : <Product current_store={current_store} storeid={currentCategory && currentCategory.store} combo={combo} products={comboProducts}/>
   
            }
            
            <Footer/>  

        </div>}
        </>
    )
}





import React, {useState, useEffect} from 'react'
import {Row, Col, Image, Button, Drawer, Radio } from 'antd'
import {cartCouponSelector } from '../../../api/cart'
import styled from 'styled-components'
import loadingImage from '../../../images/load2.gif'
import {  useDispatch, useSelector } from 'react-redux';
import {fetchUserDetails, userAuthSelector} from '../../../api/auth'
import { createComboOrder, orderSelector} from '../../../api/placeOrder'
import {Link} from 'react-router-dom'
import { GoLocation } from 'react-icons/go';
import axios from 'axios'
import keyUri  from '../../../key'
import distance from '../../../shared/distanceMeasure'



export default function Product({products, storeid, current_store, combo}) {



  const [isTakeAway,setIsTakeAway]=useState('takeaway')

  // const deliveryCharge = isTakeAway === 'homedelivery' && (minOrder > total) ?  dCharge() : 0 


  const dispatch = useDispatch()

  const {cartCoupons} = useSelector(cartCouponSelector)
  const { user} = useSelector(userAuthSelector)
  const {isOrderPlaced } = useSelector(orderSelector)
  const [visible, setVisible] = useState(false);
  const [orderStatus, setOrderStatus] = useState(false)

  const [store, setStore] = useState()

  useEffect(()=>{
        
    axios.get(keyUri.BACKEND_URI + `/current-store/${products[0]?.store}`).then(({data})=>{

      setStore(data?.store)

    })

}, [])



  const handleChange = e=> {
    setIsTakeAway(e.target.value)
    
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };


  const dChargefun = () => {

console.log(user, store);
    if(user.address && store) {

        const dist = distance(user.address.lat, user.address.lng, store.loc.coordinates[1], store.loc.coordinates[0], 'K').toFixed(2)
    

        if(store.deliveryCharges) {
    
            const delivercharge = (dist < 1)? store.deliveryCharges.above_one 
    
            : (dist < 2)? store.deliveryCharges.above_two : store.deliveryCharges.above_three 
    
         return  delivercharge
    
        }
    
    
    }
}

let offer_price = parseInt(combo &&( combo?.current_total*((100- combo?.offer)/(100))))
let delivery_charge = ((store?.minOrder < offer_price ) && isTakeAway !== "takeaway")? dChargefun() : 0

let total = parseInt(combo &&( combo?.current_total*((100- combo?.offer)/(100)))) + delivery_charge

let total1 = parseInt(combo &&( combo?.current_total*((100- combo?.offer)/(100))))

    return (
        <ProductWrap className="px-3 ">
          {  combo &&  <div className="my-3 text-center shadow-sm bg-light product" gutter={20} >
          <h5 className="p-2 text-center mb-0">{combo && combo.combo_title}</h5>
               <div className="p-2 bg-light imageclass" style={{height:"210px"}}>
               <Image
                      height="100%"
                      src={keyUri.BACKEND_URI+`/combo-image/${combo.combo_image}`}
                      placeholder={
                        <Image
                          preview={false}
                          src={loadingImage}
                          width={100}
                          height="100%" />
                      }
                />
                 </div>
                 <div>
                      {
                  products.map((item, i)=>{
                      return <b key={i} className=" mb-0">{item.product_name} <small>({item.quantity})</small> 
                      {
                        i < products.length- 1 ? ' + ' : null
                      }
                       </b> 
                     
                  })
                }
                 </div>
                
                 <div>
              <Row className="my-2 text-left shadow-xl product">
              <Col  span={16}>
                {/* <p className="ml-2 ">Total Price: &#x20B9;{combo.current_total} </p> */}
                <button className="sbtn">Offer Price</button><span className="ml-2 text-danger" style={{textDecoration:'line-through'}}>${combo.current_total}</span>
                <b className="ml-2 text-success">&#x20B9;{parseInt(combo &&( combo.current_total*((100- combo.offer)/(100))))} </b>
                                             
              </Col>
              <Col  span={6}>
                  <div className="offer d-flex justify-content-center">
                  <p>{combo.offer}% </p>
                
                 </div>

              </Col>
            </Row>
       
            </div>
            <div style={{paddingTop:'20px', position:'absolute' , right:'8%'}}>
            
            <div className="d-flex align-items-center justify-content-between px-4 mb-2" >
                     <Radio.Group onChange={handleChange} value={isTakeAway} >
                      <Radio value="takeaway">Take away</Radio>
                      <Radio value="homedelivery" >Home delivery</Radio>
                     
                      </Radio.Group>

                     </div> 

                    <p>Delivery Charges: <b>₹{delivery_charge}/-</b></p> 
                     <p>Total Charges: <b>₹{total}/-</b></p> 


            {

               user ? <Button type="primary" disabled={isOrderPlaced} onClick={()=>setVisible(true)}>Order now</Button>
                 : <Button type='primary'>
                 <Link to='/login'>Login</Link>
             </Button>
            }

                   </div>
          </div>
}

<Drawer
        title="Your Delivery Address"
        placement="bottom"
        closable={false}
        onClose={onClose}
        visible={visible}
        height={275}
      >
     {user.address  && <p>Building Name :&nbsp; <b>{user.address.building_name}</b> &nbsp; &nbsp; &nbsp; Door No : <b className="ml-1">{user.address.door_number}</b></p>}

     {user.address  && <p><GoLocation
     style={{fontWeight:"bold", fontSize:"1.5rem"}}
     className="text-danger px-1"/>{user.address.defaultAddress}</p>}



<Link to="/google-map-address?ischange=true">
     <Button className="ml-2 mb-3" 
     style={{  color: "white" }}
     size="small" type="primary" >Change Address</Button>
</Link>


<div className="d-flex justify-content-center">
     <Button 
     disabled={orderStatus ? true : false}
     style={{  width:'300px' , color: "white",background: !orderStatus ? "#4ba04b" : 'rgb(115 167 115)'}} type="ghost" 
        onClick={()=>{
            dispatch(createComboOrder(combo, user.phone_number, delivery_charge, total1, isTakeAway ))
            setOrderStatus(true)
            axios.get(keyUri.BACKEND_URI + `/combo-views/${combo._id}`)        

            }}
            
            >
                            
                  Place Order    
     </Button >

    </div>

      </Drawer>      

        </ProductWrap>

        
    )
}


const ProductWrap =  styled.div`


.track{

position:absolute;
bottom:10%;
width:90%;

}
.addcartbtn{
padding:2px 2px;
background-color:#3498DB;
    color:white;
    border:none;
    width: 100px;
    font-size:13px;
    font-weight:600;
border-radius:3px;
&:focus {

outline: none;
}  
}

.sbtn{

    padding: 1px 5px;
    background-color:orange;
    color:white;
    border:none;
    border-radius:4px;
    margin:0 0 1rem 1rem;
    margin-left:1rem;
    font-size:12px;

}

.cartbtn{

  position:absolute;
  bottom: 15%;
    left: 116%;
}


.product{



  .offer{
  margin-top:1rem;
  width:40px;
  height:40px;
  background-color:orange;
  position:absolute;
  top:-700%;
  right:-1%;
  color:white;
  clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%);

  p{

    margin-top:0.5rem;
    font-size:15px;
    font-weight:900;
  }
}
}



`
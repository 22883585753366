import React, { useEffect, useState, useRef } from 'react'
import { Carousel, Row, Col, Image  } from 'antd';
import Banner from '../../components/user/offers/offers';
import Stores from './dstore'
import MStores from './stores'
import loadingImage from '../../images/load2.gif'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import { fetchNearStores, fetchOffer, storesSelector} from '../../api/stores'
import { userAuthSelector} from '../../api/auth'
import Loader from '../../shared/loader'
import Footer from '../../global/footer'
import keyUri from '../../../src/key'
import {useMedia} from 'react-use';
import bannerImg from '../../images/banner.jpg'
import {AiFillLeftCircle, AiFillRightCircle} from 'react-icons/ai'
import axios from 'axios'

export default function HomeIndex({ history}) {
  
  
  const isWide = useMedia('(min-width: 480px)');

  const slider = useRef();
  const dispatch = useDispatch();

const {near_stores, allOffers, loading } = useSelector(storesSelector)
const { user } = useSelector(userAuthSelector)


useEffect( ()=> {

  if(!user && !loading) {
    return  history.push('/login')   
  }
  

dispatch(fetchOffer())
user && dispatch(fetchNearStores(user.address))

}, [dispatch])


const onClick = (e, item)=>{
  console.log(item)
  axios.get(keyUri.BACKEND_URI + `/offer-views/${item._id}`)        
}

    return (
 <>
 {

loading? <Loader/> :  <HomeWrap className=" pb-0" style={{paddingTop:'2.4rem',
backgroundImage:`url(${bannerImg})`, backgroundPosition:"center"}}>

<Carousel  ref={ref => {
  
            slider.current = ref;
          }}
           autoplaySpeed={7000} speed={1000} autoplay    dots={false}>

{
        allOffers.map((item, i)=>{


             return (item.offer_theme !== 'text') ? <a key={i} target={item.link ? '_tab': null} href={item.link ? item.link : `/products/${item.category}`} onClick={(e)=>onClick(e, item)}>

                <Row className="bg" align="middle"  key={i} style={{borderRadius:"5px",
                height:isWide? "280px" : "130px", backgroundColor: (item.offer_theme === 'blue') ? "rgba(234, 243, 255, 0.7)" : "rgba(255, 228, 228, 0.7)", padding:"5px 0 5px 0"}} className=" text-left shadow-sm product" gutter={20}>
                
                   <Col span={7} >
                       <div className="imageclass" >
                       <Image
                            height="100%"
                            width={isWide? "50%": ""}
                         className={isWide ? 'ml-5' : 'ml-2'}
                         preview ={false}
                            src={keyUri.BACKEND_URI+`/offer-image/${item.offer_image}`}

                            placeholder={
                            <Image
                                preview={false}
                                src={loadingImage}
                                width={100}
                                height="100%"
                                        />
                            }
                        />
                  </div> 
                  </Col>
                    <Col span={13}>
                    <h6 className="text-left my-0 store text-capitalize">{item.store.store_name}</h6> 
                    <h5 className="text-left my-0 title text-capitalize">{item.offer_title}</h5> 
                    <h6 className="text-left mb-2 text-capitalize des" style={{paddingRight:'1px'}}>{item.offer_Discription}</h6>
              
                    <button className="sbtn" style={{backgroundColor: (item.offer_theme === 'blue') ? null : 'rgb(230 57 57)'}}>Offer Price</button>
                     <small className="ml-2 price"><del>&#x20B9;{item.current_price}</del></small>
                     <b className="ml-2  price">&#x20B9;{parseInt((item.current_price*((100- item.offer)/(100))))}</b>
                   
                    </Col>
                   { item.offer && <Col span={3}>
                         <div className="offer  d-flex justify-content-center" style={{backgroundColor: (item.offer_theme === 'blue') ? null : 'rgb(226 61 61)'}} >
                        <p className="mb-0">{item.offer}%</p>
                        </div>
              
                        
                    </Col>}

                </Row>
                </a> 
                
 : <a key={i} target={item.link ? '_tab': null} href={item.link ? item.link : `/store/${item.store._id}`}>

                 <Row className="bg" align="middle" key={i} style={{borderRadius:"5px", backgroundColor: "rgb(255 236 198)", height:isWide? "280px" : "130px" }} className=" py-3 px-3 text-left shadow-sm product" gutter={20}>
                 
                    
                     <Col span={24}>
                     <h6 className="text-left my-0 store text-capitalize">{item.store.store_name}</h6> 
                     <h5 className="text-left mb-0 text-uppercase title">{item.offer_title}</h5> 
                     <h6 className="text-left mb-2 text-capitalize des" style={{paddingRight:'1px'}}>{item.offer_Discription}</h6>
     
                     </Col>

 
                 </Row>
                 </a>  
               })
            }
  </Carousel>
  
{allOffers.length > 1 && <><AiFillLeftCircle onClick={()=>slider.current.prev()} className="larrow"/>
<AiFillRightCircle onClick={()=>slider.current.next()} className="rarrow"/> </>}

<div style={{backgroundColor:"rgba(255, 255, 255, 0.97)"}}>
<h4  className="p-1 text-white text-center ">NEAR STORES</h4>
<br/>
  {

loading ? <Loader/> : isWide ? <Stores store={near_stores}/> : <MStores store={near_stores}/>

  }  
</div>
<Footer/>  

        </HomeWrap> 
         }

        </>

    )
}
const HomeWrap = styled.div`
background-size:cover;
position: relative;

.larrow, .rarrow{
  position:absolute;
  color:grey;
  top:17%;
  font-size:1.3rem;
  opacity:0.7;
  cursor: pointer;

}
.larrow {

left:3%;

}

.rarrow {

  right:3%;


}

.ant-carousel .slick-dots button{

    background-color:#525252d9;

}
.ant-carousel .slick-dots li.slick-active button{

  background-color:grey;
}

.slick-dots li button{

  &:before{

display:none;

}
}
.slick-current .product {

  z-index:22 !important;
}

.sbtn{
  padding: 1px 5px;
  background-color:#3e91f7;
  color:white;
  border:none;
  border-radius:4px;
  margin-top:0.5rem;
  font-size:10px;
}

.link{
  font-size:10px;
}

.store{
  font-size:14px;
  padding-bottom:4px;
  color: #383838;
}

.des{
  font-size:14px;
  padding-bottom:2px;

}

.title{
  font-size:17px;
  font-weight:700;
  padding-bottom:5px;
}

.price{
  color:black;
}

.product{
position:relative;
.offer{
width:50px;
height:50px;
background-color:#3e91f7;
position:absolute;
transform: translateY(-52px);
right:50%;
margin:0 ; 
color:white;
clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%);

p{
  margin-top:0.5rem;
  font-size:20px;
  font-weight:900;
  
}
}
}
h4{

font-size:1rem; 
background-image: linear-gradient(-225deg, #22E1FF 0%, #1D8FE1 48%, #625EB1 100%);

}
.imageclass{
position:relative;
}


@media(min-width:900px){

.store {

font-size:1.7rem;

}
h4{

 font-size:1.7rem; 
}
.title{
font-size:2.5rem;

}
.des {

  font-size:1.4rem;
}
.sbtn{
  padding: 1px 5px;
  background-color:#3e91f7;
  color:white;
  border:none;
  border-radius:4px;
  margin-top:0.5rem;
  font-size:1rem;
}
.price {

  font-size:1.2rem;
}

.product{
position:relative;
.offer{
width:150px;
height:150px;
background-color:#3e91f7;
position:absolute;
transform: translateY(-75px);
right:5%;
margin:0 ; 
color:white;
clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%);

p{
  margin-top:2rem;
  font-size:3.5rem;
  font-weight:900;
  
}
}
}

}




`
import React, {useState,useCallback, useEffect} from 'react'
import {useParams, useLocation} from 'react-router-dom'
import Loader from '../../../shared/loader'
import Product from './product'
import DProduct from './dproducts'
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import Footer from '../../../global/footer'
import styled from 'styled-components'
import {useMedia} from 'react-use';
import axios from 'axios'
import key from '../../../key'
import {useDispatch, useSelector} from 'react-redux'


export default function StoreIndex() {
    const dispatch = useDispatch()

    const [state, setState] = useState([]);
    const [page, setPage] = useState(1);
    const { id } = useParams()
 const [loading, setLoading] = useState(false)
  const [currentCategory, setcurrentCategory] = useState()
  const [hasnext, setHasNext] = useState()



useEffect(()=>{

   setLoading(true)
       
       axios.get(key.BACKEND_URI + `/current-category/${id}?skip=${page}&limit=10&clients=true`).then(({data})=>{     
            setLoading(false)
            setcurrentCategory({store:data.curr_category.store, category_name:data.curr_category.category_name})
            setState([...state, ...data.curr_category.products]);
           
        })  
  
    }, [ page])





    const handleOnDocumentBottom = useCallback(() => {
        (hasnext !== 0) ?
        setPage(page + 1) : setPage((page)=>page)
     
    }, [page]);

      useBottomScrollListener(handleOnDocumentBottom);

    const isWide = useMedia('(min-width: 480px)');


    return (
        <div className="px-2 pb-5 pt-4">

            {

                isWide ?<DProduct
                title={currentCategory && currentCategory.category_name}
                storeid={currentCategory && currentCategory.store} products={state}/> :
                 <Product
                 loader= {loading}
                storeid={currentCategory && currentCategory.store} products={state}/>
               
                
            }
        
        

            <Footer/>  

        </div>
    )
}


const CartWrap = styled.div`

.cart{

    position:fixed;
    bottom:10%;
    width:100%;

}

`


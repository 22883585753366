import { configureStore } from '@reduxjs/toolkit';
import storesReducer from './stores';
import userReducer from './auth'
import cartReducer from './cart'
import orderReducer from './placeOrder'



export default configureStore({
  reducer: {
        stores: storesReducer,
        user_auth:userReducer,
        cart:cartReducer,
        order:orderReducer
      

  },
});

import React, { useEffect, useState } from 'react'
import Banner from './banner'
import DBanner from './desktop/banner'

import {Input, Affix, message} from 'antd'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {fetchOneStore, storesSelector, fetchCombo} from '../../api/stores'
import Loader from '../../shared/loader'
import Catagory from './catagory'
import DCatagory from './desktop/category'

import DCombo from './desktop/combo'
import axios from 'axios'
import keyUri from '../../key'
import Combo from './combo'
import Product from '../store/products/product'
import Rproducts from './rproducts'
import Footer from '../../global/footer'
import {useMedia} from 'react-use';

const { Search } = Input;


export default function StoreIndex() {

    const isWide = useMedia('(min-width: 480px)');

const [sresult, setSresult] = useState([])  
const [issearch, setIsSearch] = useState(false)  
const {id} = useParams()
const dispatch = useDispatch()
const {current_store, allCombo, currentStoreProducts, loading} = useSelector(storesSelector)
const [ploading, setPLoading] = useState(false)

    useEffect(()=>{

        dispatch(fetchOneStore(id))
        dispatch(fetchCombo(id))
        // axios.get(keyUri.BACKEND_URI + `/update-views/${id}`)
    }, [dispatch])
    


        const onSearch = async (e) =>{

            setPLoading(true)

            setIsSearch(true)
            const {data} = await axios.get(keyUri.BACKEND_URI +`/current-store/${id}?filter=${e.target.value}`)
         
   if(!Array.isArray(data) && e.target.value !== ''){

  return  message.warn('no items found!')

   }

        if(e.target.value === '') {

           
         return   setIsSearch(false) 
            
        }

                setIsSearch(true) 
                setSresult(data) 

              setPLoading(false)
           
            
    }

    return (
        <div className="px-0 pb-5 pt-4 ">
            {

                (loading && ploading) ? <Loader/> :
                
                
                <>
{              isWide ? <DBanner storeinfo={current_store} /> :  <Banner storeinfo={current_store}/>
}                <div className="container">
                            <Search placeholder="Search products"
           
                onChange={onSearch} enterButton />  
      

            { 
          
            issearch ? null : isWide ? <>  <DCatagory/>
           
                <DCombo combo={allCombo}/>
             
                <Rproducts products={currentStoreProducts}/> </>  : <>  <Catagory/>
                <Combo combo={allCombo}/>
                <Rproducts products={currentStoreProducts}/> </>
                
        } 

            {
             issearch &&  <Product loader={ploading} products={sresult} storeid={current_store._id} />

            }
</div>
                </>
            }
            
            <Footer/>  
  
        </div>
    )
}

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { message } from 'antd';
import keyUri from '../key'


const token = localStorage.getItem('piktoken') ?
 localStorage.getItem('piktoken') : null


const storeInfo = localStorage.getItem('store') ?
 localStorage.getItem('store') : null



 

const initialState = {

    user:null,
    isOtpMatch: false,
    token:  token ,
    loading:false,
    hasError:false,
    // isUserAuthenticate: isAuth,
    userEnterStore:storeInfo ? storeInfo : null,
    favourites:[],
    chat:[],
    phone:null

}


export const userAuthSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {

    getUserLogin: state => {
      state.loading = true;
    },

    getUserLoginSuccess:  (state, {payload}) => {
      
      state.loading = false
      state.token = payload.token
      state.user = payload.user

    },

    getActiveEmail: (state, {payload})=>{
      state.loading = false
      state.user = payload
      state.favourites = payload.favourites

    },
   

    getUserLoginFailure: (state) => {

      state.loading = false
      state.hasError = true
    },

    getFavourite: (state, {payload}) =>{

      state.loading = false
      state.favourites = payload
    },

    getFavouriteSuccess: (state, {payload}) => {
      state.loading = false
      state.favourites = payload
    }, 

   

     getFavouriteFailure: (state) => {
 
       state.loading = false
       state.hasError = true
     },

     getRegbyPhone: (state, {payload}) =>{
      state.loading = false
      state.user = payload.user
     },

     getOtpVerify : (state, {payload}) =>{
      state.loading = false
      state.isOtpMatch = payload.isOtpMatch

     },
     getProfile: (state, {payload}) => {

      state.loading = false
      state.user = payload.user
      state.favourites = payload.user.favourites
      
      
     },

     getPhoneNumber:(state)=>{

      state.loading = false
     }

  },
});

export const { getUserLogin, getProfile, getRegbyPhone, getPhoneNumber,  getActiveEmail,
  getCurrent_User_Success, getOtpVerify, getUserLoginSuccess, getUserLoginFailure, getUserRegister,getFavourite,getFavouriteSuccess, getFavouriteFailure, getCurrentFavourite } = userAuthSlice.actions;
export const userAuthSelector = state => state.user_auth;


const config = {
  headers: {
      Accept: "application/json",

  }
};


export const fetchUserlogin = (values) => async dispatch => {

  dispatch(getUserLogin())

  const key = 'login';
    message.loading({ content: 'loading...', key })

 try {

  const {data} = await axios.post(keyUri.BACKEND_URI + '/userAuth', values, config)
 data && message.success({content:data.msg, key, duration:2})

  dispatch(getUserLoginSuccess(data));


  localStorage.setItem('piktoken', data.token)

 } catch ({response}) {

     response.data && message.error({content:response.data.msg, key, duration:2})

      dispatch(getUserLoginFailure())
   
 }
}


export const fetchUserDetails =(id) => async dispatch =>{


  try {
    const {data} = await axios.get(keyUri.BACKEND_URI + `/profile/${id}`)
    
    dispatch(getActiveEmail(data));
     
   } catch ({response}) {

  dispatch(getUserLoginFailure())
     
   }
}


export const fetchUserProfileDetails =(token) => async dispatch =>{

  dispatch(getUserLogin())

  const loginConfig  = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  try {
    const {data} = await axios.get(keyUri.BACKEND_URI + `/user-profile`, loginConfig)
    
    dispatch(getProfile(data))


    
   } catch ({response}) {


    dispatch(logout())

  dispatch(getUserLoginFailure())
     
   }
}





export const fetchUserPhoneAuth = (phone, isAuth) =>async dispatch => {

  dispatch(getUserLogin())

  let data = {
    phone,
    isAuth
  }
try {
  
  dispatch(getRegbyPhone(data))

} catch (error) {

  console.log(error);
  
  
}
  
}

export const changeUserAddress = (values, {_id}) =>async dispatch => {

  dispatch(getUserLogin())

try {
  
  const {data} = await axios.post(keyUri.BACKEND_URI + `/user-address/${_id}`, values, config)

  dispatch(getRegbyPhone(data))

} catch (error) {
  
}
 
}


export const addUserAddress = (values, _id) =>async dispatch => {

  dispatch(getUserLogin())
try {
  
  const {data} = await axios.post(keyUri.BACKEND_URI + `/user-address/${_id}`, values, config)

  localStorage.setItem('piktoken', data.token)
  dispatch(getRegbyPhone(data))

} catch ({response}) {
  

  dispatch(getUserLoginFailure())
   

}
 
}


//sent-otp
export const fetchSendotp =  (values)=> async dispatch => {
// debugger;

  const key = 'verify';
    message.loading({ content: 'loading...', key })
  try {
    
  const {data} =   await axios.post(keyUri.BACKEND_URI + `/send-otp`, values)
dispatch(getPhoneNumber())
console.log({data});

message.success({content:data.msg, key, duration:2})

  } catch ({response}) {

    dispatch(getUserLoginFailure())
   console.log(response);
    response.data && message.error({content:response.data.msg , key, duration:2})
    // window.location.reload()
  }

}

// //verify otp
// export const fetchverifyotp = (values) => async dispatch => {
  
//   const key = 'verify';
//   dispatch(getUserLogin())
//   message.loading({ content: 'loading...', key })

//   try {
    
//     const { data } = await axios.post(keyUri.BACKEND_URI + `/recive-otp`, values)
//     dispatch(getOtpVerify(data))
//     data && message.info({content:data.msg, key, duration:2})  

//   } catch (response) {
//     dispatch(getUserLoginFailure())
//     response.data && message.error({content:response.data.msg, key, duration:2})
//   }
// }


//add password
export const addUserPassword = (values) =>async dispatch => {
  
  const key = 'verify';
  dispatch(getUserLogin())
  message.loading({ content: 'loading...', key })
try {
  
  const {data} = await axios.post(keyUri.BACKEND_URI + `/user-password`, values)
  dispatch(getUserLoginSuccess(data))
  data && message.success({content:data.msg, key, duration:2})  

} catch ({response}) { 
  dispatch(getUserLoginFailure())
  response.data && message.error({content:response.data.msg, key, duration:2})
}
}



export const addFavourite = (id, product) => async dispatch =>{
  console.log(id, product);
  const key = 'verify';
  dispatch(getUserLogin())
  message.loading({ content: 'loading...', key })
try {
 const {data} =  await axios.post(keyUri.BACKEND_URI + `/favproduct/${id}`, product, config)

 data && message.info({content:data.msg, key, duration:2}) 

  dispatch(getFavouriteSuccess(data.favourites))

  window.location.reload()

} catch ({response}) {
  dispatch(getUserLoginFailure())

  response.data && message.error({content:response.data.msg, key, duration:2})
 
}
}

export const fetchFavourite = (id) => async dispatch => {


  
  const key = 'verify';
  dispatch(getUserLogin())
  message.loading({ content: 'loading...', key })

 try {
  const {data} = await axios.get(keyUri.BACKEND_URI + `/favproduct/${id}`)

  dispatch(getFavouriteSuccess(data.favProduts));
 } catch ({response}) {

dispatch(getFavouriteFailure())
  response.data && message.error({content:response.data.msg, key, duration:2})


 }
}






export const logout = ()=> async dispatch=>{
    try {
        localStorage.removeItem('piktoken')
        window.location.reload()
    } catch (error) {
        dispatch(getUserLoginFailure())
    }
}



export const  updateUser = ( values, id) => async dispatch =>{



  const key = "user"
  dispatch(getUserLogin())
  message.loading({ content: 'loading...', key })
try {

    const {data} = await axios.put(keyUri.BACKEND_URI +`/userprofile/${id}`, values, config);
    data && message.success({ content: data.msg, key, duration: 2 });
    dispatch(getPhoneNumber())

} catch ({response}) {
    dispatch(getUserLoginFailure())
    response.data && message.error({content:response.data.msg, key, duration:2})


}

}

export const fetchSendPasswordOTP =  (phone)=> async dispatch => {
  const key = 'verify';
    message.loading({ content: 'loading...', key })
  try {
    
  const {data} =   await axios.get(keyUri.BACKEND_URI + `/password-otp/${phone}`)
dispatch(getPhoneNumber())
message.success({content:data.msg, key, duration:2})

  } catch ({response}) {
    dispatch(getUserLoginFailure())
    response.data && message.error({content:response.data.msg, key, duration:2})
    window.location.reload()
  }
}


export const  updatePassword = ( values, phone) => async dispatch =>{
  const key = "user"
  // dispatch(getUserLogin())
  message.loading({ content: 'loading....', key })
try {
    const {data} = await axios.put(keyUri.BACKEND_URI +`/update-password/${phone}`, values, config);
    data && message.success({ content: data.msg, key, duration: 2 });
    dispatch(getPhoneNumber())
    data && window.location.reload()
} catch ({response}) {
    dispatch(getUserLoginFailure())
    response.data && message.error({ content: response.data.msg, key, duration: 2 });
}
}


export const  resetPassword = ( values, phone) => async dispatch =>{
  const key = "user"
  // dispatch(getUserLogin())
  message.loading({ content: 'loading....', key })
try {
    const {data} = await axios.put(keyUri.BACKEND_URI +`/reset-password/${phone}`, values, config);
    data && message.success({ content: data.msg, key, duration: 2 });
    dispatch(getPhoneNumber())
    data && (window.location.href='/login')

} catch ({response}) 
{
    dispatch(getUserLoginFailure())
    response.data && message.error({ content: response.data.msg, key, duration: 2 });
}
}


export default userAuthSlice.reducer;

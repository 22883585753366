import React, { useEffect } from 'react'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import { userAuthSelector} from '../../../api/auth'
import {fetchUserOrder, orderSelector, fetchOrder} from '../../../api/placeOrder'
import Loader from '../../../shared/loader'
import Footer from '../../../global/footer'
import OrderTable from './orders'

export default function FavouriteIndex() {
const {id} = useParams()
const dispatch = useDispatch()

const { allOrder, loading } = useSelector(orderSelector)   

useEffect(()=>{

    dispatch(fetchUserOrder(id))
 
    }, [dispatch])
    

    return (
        <div className="px-2 pb-5 pt-5">

            {

                loading ? <Loader/> : <OrderTable  data = {allOrder}  />
 
            }
            
            <Footer/>  

        </div>
    )
}





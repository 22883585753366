import React, {useState, useEffect} from 'react'
import {Row, Col, Image, Space, Button,  Drawer } from 'antd'
import {cartCouponSelector,  addCartCoupons, qtyupdate } from '../../../api/cart'
import styled from 'styled-components'
import keyUri from '../../../key'
import loadingImage from '../../../images/load2.gif'
import Fade from 'react-reveal/Bounce'
import {  useDispatch, useSelector } from 'react-redux';
import {  MdAddShoppingCart } from "react-icons/md";
import cart from '../../../images/cart.png'
import { HeartFilled } from '@ant-design/icons';
import {addFavourite, userAuthSelector} from '../../../api/auth'
import { Link, useParams } from 'react-router-dom';
import emptycart from '../../../images/emptycart.png'
import discount from '../../../images/discount.svg'
import Banner from '../../../shared/banner'

export default function Product({products=[],  search, storeid, title, start_search}) {

  
  const { id } = useParams()

  const dispatch = useDispatch()

  const {cartCoupons, total} = useSelector(cartCouponSelector)
  const { user } = useSelector(userAuthSelector)

  const [visible, setVisible] = useState(false);

  const [cartData, setCartData] = useState(false);



  let cartProducts = localStorage.getItem('cart') ? JSON.parse( localStorage.getItem('cart') )  : []



useEffect(() => {

  if(cartProducts.length > 0){
    cartProducts && setCartData(true)
}
}, [dispatch])


    const clearCart = () => {
           
      localStorage.removeItem('cart')
         
          setVisible(false)

          window.location.reload()
    };

    
const onAdd = (data) =>{

  setCartData(true)
  
  let isSameStore = cartProducts.findIndex(item => item.store === data.store)

  if(cartProducts.length > 0 && isSameStore === 0){

         dispatch(addCartCoupons(data))

  } else if(cartProducts.length > 0 && isSameStore === -1){

    setVisible(true)

  } else {

    dispatch(addCartCoupons(data))

  }

  



  }
  
  
  const increment = (id) =>{
  
      let data = {id, incr:'incr'}
    
      dispatch(qtyupdate(data))
          
    }
    
    const decrement = (id) =>{
    
      let data = {id, decr:'decr'}
     
      dispatch(qtyupdate(data))
      
    
    }
  

  
    const onClose = () => {
      setVisible(false);
    };



  const isFavirote = (id)=>{
 
  let isthere =   user && user.favourites.findIndex((item)=>{

  return item && item._id === id
    })
  return (isthere !== -1) ? {color:"#c52c2c"}:{color:"#d2d2d2"} 

}




if(products.length < 1 && start_search ) {

  return ( <div>

    <img src={emptycart} alt="noitem" width="100%"/>
    <h6>NO Items Found !</h6>
  </div> )
}



    return (
      <>
{ !search &&  <Banner title={title}/>
}    
   <ProductWrap className="px-3 pb-3 mt-5 container">
           <Row  style={{borderRadius:"5px"}} className=" text-left  product" gutter={50}>
            {

               products.map((item, i)=>{
          
 


                const exist = cartCoupons.findIndex((isthere) => isthere._id === item._id);

                return <>{
                    (item.stock !== 0) ?
               <Col span={6}>
                <div key={i}  style={{borderRadius:"5px"}} className="my-4 text-left shadow-sm product" gutter={20}>

                     
                  
                 
                       <div className="p-2 bg-light  imageclass" style={{height:"130px"}}>
                    
                       <img
     className="d-block mx-auto"
     style={{width:"40%", height:"100%"}}  src={keyUri.BACKEND_URI+`/product-image/${item.image}`} alt={item.image}/>
    


{/* className="mx-auto d-block rounded-circle shadow-sm" height="60px" width="74%" */}


<HeartFilled
style={isFavirote(item._id)}
className="heart p-1 rounded-circle shadow-sm" 
onClick={()=>dispatch(addFavourite(user._id,  {productid:item._id}, storeid, id)) }/>


                       {/* <img src={keyUri.BACKEND_URI+`/product-image/${item.image}`} alt="hello" height="100%"/> */}
                  </div> 
            
                      <div className="p-3" >
                    <h6 style={{fontSize:"0.75rem", lineHeight:1.45, letterSpacing:'0.3px'}} className="text-left text-capitalize mb-0">{item.product_name}</h6>
              
                    <small>{item.quantity}</small><br/>
                    <p>{item.product_description}</p>
                    <button className="sbtn">Sale Price</button>
                     <small className="ml-2 ">&nbsp;
       {item.offer_price && <span style={{textDecoration:"line-through"}}>&#x20B9;{ item.sale_price}</span>}
                      &nbsp; &#x20B9;{item.offer ? item.offer_price:
                     item.sale_price  }</small>
<div className="cartbtn">
                     {


  (exist === -1)?   
<Fade>
  
  
  <button  onClick={()=>onAdd(item)} className="addcartbtn">


Add &nbsp; <MdAddShoppingCart className="addcart"/>

</button></Fade> : <Fade>
    <Space size={0} >
 <button className="add" style={{borderTopLeftRadius:"4px", borderBottomLeftRadius:"4px"}}  disabled={(exist !== -1) && cartCoupons[exist].units === 1 ? true : false} type="primary" onClick={()=>decrement(item._id)}>-</button>
 <button className="qty" >{(exist !== -1) && cartCoupons[exist].units}</button>
 <button className="add" style={{borderTopRightRadius:"4px", borderBottomRightRadius:"4px"}}  type="primary" onClick={()=>increment(item._id)}>+</button>


</Space></Fade>


                     }


</div>
</div>


{ item.offer && 
<>
<p className="offer px-2"><img className="mx-1" src={discount} width="20px"/>{item.offer}%</p>
</>
}
           </div>
           </Col>
           : ''
            }
           </>

               })
            }

</Row>
            {
                  cartData && <div className="cart ">
                     <Fade duration = {1000} bottom >
                        <Link to={`/cart?id=${id}`}>   
                        <Button type="primary" style={{ width:'360px', height:'35px', color: "white",background:"#4ba04b" , border:'none'}} > 
                        <div className="d-flex align-items-center justify-content-between px-1"  > 
                        <h6 style={{ color: "white"}}> {cartCoupons.length} |  &#8377; {total}</h6> <h6 style={{ color: "white"}}> View Cart </h6> 
                        </div>
                        </Button></Link>
                        </Fade>
                    </div>
            }


<Drawer
        
        placement="bottom"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
       
       <div>

<img src={cart} alt="cart" width="70px"/><br/>
<p>Your Cart Contains items from previous store Do you want to clear the cart and add items from 
this store
</p>

<Button className="mr-3" onClick={()=>setVisible(false)} type="link">Cancel</Button>
<Button type="primary" onClick={()=>clearCart()}>Clear Cart</Button>


 </div>
      </Drawer>
        </ProductWrap>
        </>
    )
}


const ProductWrap =  styled.div`

.cart{
position:fixed;
bottom:7.5%;
left:0%;
width:100%;
}


.addcartbtn{

padding:2px 2px;
background-color:#3498DB;
    color:white;
    border:none;
    width: 80px;
    font-size:13px;
    font-weight:600;
border-radius:3px;
&:focus {

outline: none;

}  
    svg{
    font-size:15px;
    font-weight:600;

      
    }



}
.sbtn{

    padding: 1px 5px;
    background-color:orange;
    color:white;
    border:none;
    border-radius:4px;
    margin-top:1rem;
    font-size:10px;

}

.cartbtn{

  position:absolute;
bottom: 7%;
    left: 62%;

}

.add, .qty {

     padding: 2px 12px;
    background-color:#AFD6F0;
    color:white;
    border:none;
    
   
    font-size:12px;
    font-weight:600;

 &:focus {

  outline: none;

 }   
}

.qty {

    background-color:#3498DB;
    color:white;
    border-radius:0
 
}

.product{

position:relative;


  .offer{

  position:absolute;
  top:6%;
  right:-1%;
  font-size:12px;
  font-weight:900;
  color:grey;
  
}
}

.imageclass{


  position:relative;
}

.heart{

font-size:1.1rem;
position:absolute;
bottom:5%;
right:5%;
color:#c7c7c7;
font-weight:200;
background-color:#fdfdfd;

}



`


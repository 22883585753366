import React from 'react'
import {Row, Col, Button} from 'antd'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import { RightOutlined } from '@ant-design/icons';
import moment from 'moment'
import storeImgUri from '../../../key'


export default function Stores({data}) {
    if(data.length < 1) {

     return   <h5 className="py-5 text-center text-secondary">No orders Available</h5>
    }
    return (  
        <OrderWrap className="container my-3">
        {
            data.map((item, i)=>{
             return  <div key={i}  className=" my-3 rounded bg-light shadow-sm" >             
                <Link key={i} to={`/order/${item._id}`} style={{color:'black'}}> 
                    <Row >
                        <Col span={22} className="text-left pl-3 pt-1">                   
                        <h6>Order Id: {item.orderId  }</h6>
                        <b>{item.store.store_name}</b> <br/>
                      <small>{ moment(item.createdAt).format("MMM Do YYYY")}</small>  <br/>                 
                       <small>Time:&nbsp;{moment(item.createdAt).format('h:mm:ss a')}</small>
                        </Col >
                        <Col spam={2} style={{paddingTop:'35px'}}>
                        <RightOutlined />
                        </Col>
                    </Row> 
                      </Link>
             </div>
              

            }) 
        }               
        </OrderWrap>
    )
}


const OrderWrap =  styled.div`

img{
    border-radius:5px;
}

hr{
    margin:0;
}

`



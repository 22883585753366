import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios'
import { message } from 'antd';
import  keyuri from '../key'
import socketIOClient from 'socket.io-client'

const initialState = {
    
    order:null,
    loading:false,
    hasError:false, 
    allOrder:[],
    products:[],
    isOrderPlaced:false,
    tracking:null,
    dataState:0,

}


const socket =  socketIOClient(keyuri.SOCKET_URI, { transports: ['websocket', 'polling', 'flashsocket'] });


export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
  
      getOrder: state => {
        state.loading = true;
      },
      getOrderSuccess: (state, {payload}) => {
        
        state.loading = false

        state.allOrder = payload
        state.isOrderPlaced = true
        
      },


      getCurrentOrder:(state, {payload}) => {
    
      
        state.loading = false
        state.order = payload
        state.tracking = payload.orderStatus
        state.dataState = payload.data_state

        
      },

      getTrackingStatus : (state, {payload}) =>{

          state.loading = false
          state.tracking = payload.orderStatus
          state.dataState = payload.data_state
    
    
      },

      getOrderFailure: (state) => {
  
        state.loading = false
        state.hasError = true
      }
    },
  });
  

export const { getOrder, getTrackingStatus, getOrderSuccess,getCurrentOrder,getPreviousOrder, getOrderFailure } = orderSlice.actions;
export const orderSelector = state => state.order;

const config = {
    headers: {
        Accept: "application/json",
    }
  };

  
  export const fetchOrder = (id) => async dispatch => {
   
    dispatch(getOrder())
   try {
    const {data} = await axios.get(keyuri.BACKEND_URI + `/order`)
    


      dispatch(getOrderSuccess(data.products));

    
     
   } catch (error) {
  dispatch(getOrderFailure())
   }
  }



  export const fetchCurrentOrder = (id) => async dispatch => {
 
    dispatch(getOrder())
   try {
    const {data} = await axios.get(keyuri.BACKEND_URI + `/order/${id}`)

  
     dispatch(getCurrentOrder(data));
     
   } catch (error) {

  dispatch(getOrderFailure())
  
   }
  }
  

  export const createOrder = (values, user, isTakeAway, deliveryCharge) => async dispatch => {

      let orderData = {
        orderProducts: values,
        user: user,
        isTakeAway: isTakeAway,
        deliveryCharge: deliveryCharge
    }

    
    localStorage.removeItem('cart')



    dispatch(getOrder())  
    const key = 'create';
      message.loading({ content: 'loading...', key })
   
    try {
     const {data} = await axios.post(keyuri.BACKEND_URI +`/order`,orderData,config)
    
     dispatch(getCurrentOrder(data.order))

     data && message.success({content:data.msg, key, duration:2})
    
   if(data) {

    window.location.href = `/order/${data.order._id}`;
   }

    


    } catch ({response}) {


   response.data && message.error({content:response.data.msg, key, duration:2})   
    }
   }
  


   
  export const createComboOrder = (values,user, delivery_charge, total, isTakeAway) => async dispatch => {
 
    let orderData = {
        orderProducts:values.combo_product,
        user:user,
        combo:values,
        delivery_charge,
        total,
        isTakeAway
    }
    dispatch(getOrder())  
    const key = 'create';
      message.loading({ content: 'loading...', key })
   
    try {
     const {data} = await axios.post(keyuri.BACKEND_URI +`/combo_order`,orderData,config)
    
     data && message.success({content:data.msg, key, duration:2})

     dispatch(getOrderSuccess(data))
  
     if(data) {

      window.location.href = `/order/${data.order._id}`;
     }
  

    } catch ({response}) {


   response.data && message.error({content:response.data.msg, key, duration:2})   
    }
   }



   export const fetchUserOrder = (id) => async dispatch => {
    dispatch(getOrder())
   try {
    const {data} = await axios.get(keyuri.BACKEND_URI + `/user-order/${id}`)
   
    
    dispatch(getOrderSuccess(data));
     
   } catch (error) {
  dispatch(getOrderFailure())
   }
  }
  export default orderSlice.reducer;
  



  export const  fetchOrderTracking = (values) => async dispatch =>{
 
    
    try {

       
      dispatch(getTrackingStatus(values))
      // window.location.reload()

    
    } catch (err) {

        dispatch(getOrderFailure())
    
    }
    }
    
import React, { useState} from 'react'
import { Form, Input, Button, Space} from 'antd';
import {resetPassword, userAuthSelector, fetchSendPasswordOTP} from '../../api/auth'

import {useDispatch,useSelector } from 'react-redux'
import {useParams} from 'react-router-dom'

// import Footer from '../../../global/footer'


export default function EditProfile() {
  
  const dispatch = useDispatch()
  const {user, loading, isOtpMatch} = useSelector(userAuthSelector)  
  const [form] = Form.useForm();
  const {id} = useParams()
  const [code, setcode] = useState(false)
  const [phone, setPhone] = useState(null)
  const [length, setLength] = useState(false)


      const layout = {
        labelCol: {
          span: 4,
        },
        wrapperCol: {
          span: 10,
        },
      };
    
      const tailLayout = {
        wrapperCol: {
          offset: 0,
          span: 6,
        },
      };


      const verifyOTP = ()=>{
        setcode(true)
        return dispatch(fetchSendPasswordOTP(phone) )
      }

        const onFinishPassword = (values) => {
            setPhone(values.phone_no)

            if(!code ){
              setcode(true)
              return dispatch(fetchSendPasswordOTP(values.phone_no) )
            }

            if (code) {
                const data = {
                  phone : values.phone_no,
                  otp : values.otp,
                 // pass:values.password,
                  new_password:values.new_password,
                  confirm_password:values.confirm_password    
                }
                return dispatch(resetPassword(data, values.phone_no))
            }          
        }

        const onFinishFailed = (errorInfo) => {
          console.log('Failed:', errorInfo);
          
        };

const onChange= (e) =>{
  console.log(e.target.value.length);
  if(e.target.value.length === 6){
   return setLength(true)
  }
   setLength(false)
  }


        

    return (

<>
       
{
    loading ? "loading..." : 
    <>
    <div className="mx-4 mb-4">

       <h6 className="mt-5 mb-4">Reset Password</h6>

   <Form
  {...layout}
  form={form}
  name="pass"
  initialValues={{ remember: true }}
  onFinish={onFinishPassword}
  onFinishFailed={onFinishFailed}
  >
    <Form.Item
            label="Phone Number"
            name="phone_no"
            rules={[{ required: true, message: 'Please input your phone number!' }]}
            >
    <Input prefix={'+91' } placeholder="Phone number" />

    </Form.Item>


   

{code && <>

   <p>Enter the 6-digit code we sent to  +91 {phone}</p>
   <Space size={3} >

   <Form.Item
        name="otp"
        rules={[{ required: true, message: 'Enter 6-digit code ' }  ]}
      >
        <Input style={{ width: '90%' }}  onChange={onChange} placeholder=" 6-digit code" /> 
      </Form.Item>
      <p onClick={verifyOTP} style={{color:'blue', paddingBottom:'8px'}}> Resend OTP </p>
</Space>

{
  length && <>

<Form.Item
        label="New Password"
         name="new_password"
            rules={[
                   {
                   min: 8,
                   required: true,
                 message: 'password must be at list 8 character!',
                },
               ]}
              hasFeedback
           >
        <Input.Password  placeholder="new password" />
   </Form.Item>

   <Form.Item
        label="Confirm Password"
         name="confirm_password"
         className='  border-none'
         dependencies={['new_password']}
         hasFeedback
         rules={[
           { 
             min: 8,
             required:true

           },
           ({ getFieldValue }) => ({
             validator(rule, value) {
               if (!value || getFieldValue('new_password') === value) {
                 return Promise.resolve();
               }
 
               return Promise.reject('The Two Passwords Entered Do Not Match!');
             },
           }),
         ]}
       >
        <Input.Password placeholder="confirm password" />
   </Form.Item>

   </>
}
   </>  
}

<Form.Item {...tailLayout}>
 <Button type="primary" size={"large"} style={{width:'150px'}} htmlType="submit"> Submit </Button> 

 </Form.Item>

</Form>
</div>
</>
}
{/* <Footer/>   */}

        </>
    ) 
    }

    




import React, { useEffect, useState } from 'react'
import {useParams} from 'react-router-dom'
import {Row, Col, Drawer, Avatar, Button, Rate } from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {fetchCurrentOrder, orderSelector, fetchOrderTracking} from '../../../api/placeOrder'
import {fetchUserDetails, userAuthSelector} from '../../../api/auth'
import {updateStorerating} from '../../../api/stores'

import styled from 'styled-components'
import Footer from '../../../global/footer'
import moment from 'moment'
import Tracking from './tracking'


export default function ProductIndex() {


    const [visible, setVisible] = useState(true);
    const [chatvisible, setChatVisible] = useState(false);


    const {id} = useParams()
const dispatch = useDispatch()
const {order} = useSelector(orderSelector)
let user_data = localStorage.getItem('user_data') ? JSON.parse(  localStorage.getItem('user_data')) : null

const {user} = useSelector(userAuthSelector)

console.log(order);
    useEffect(()=>{
  
      user_data && dispatch(fetchUserDetails(user_data._id))

        dispatch(fetchCurrentOrder(id))     

        setTimeout(()=>{

          setVisible(false)

        }, 2000);

    }, [dispatch])


    const showDrawer = () => {
        setVisible(true);
        setChatVisible(false);

      };
     const showChatDrawer = () => {
        setVisible(false);
        setChatVisible(true);

      };

      const rate = (value) => {
        console.log(value);    
        dispatch( updateStorerating( order.store._id ,value ,order._id ) )
       };

      const onClose = () => {
        setVisible(false);
        setChatVisible(false);

      };
     

    return (
        (user && order) &&  <OrderWrapProduct className="px-2 pb-5"  style={{paddingTop:"2.5rem"}}>
            {   
            <>             
            <div className=" d-flex justify-content-between align-items-center p-2 text-left">
                <div style={{width:"70%"}}>
                <h6 className="m-0">{ order.store.store_name}</h6>
                <small>Address: &nbsp;{ order.store.store_address}</small>
                </div>
                <div>
                <small>Date:&nbsp;{moment( order.createdAt).format('MMM Do YY')}</small><br/>
                <small>Time:&nbsp;{moment( order.createdAt).format('h:mm a')}</small>
            </div>
            </div>
            <div className=" d-flex justify-content-between align-items-center order  p-2 text-left shadow-sm">
                <div>
                 <h6>Order Id: { order.orderId}</h6>
                 <h6 className="my-3 " >Order Status:
                    <span className="text-white px-2 py-1 ml-2 rounded" style={{backgroundColor: (order.orderStatus === "Delivered") ? "green":
                   (order.orderStatus === "Reject")? "#d60808" :"#9c8600"
                  }}>{ order.orderStatus}</span></h6>

            </div>

            { (order.orderStatus === "Delivered") ? <div className="text-left px-3  ">
            { !order.isRated && <> <p className="my-0 pl-4">Store Rating:</p>
             <span  > <Rate disabled={order.isRated ? true : false} onChange={rate}/> </span> </>}
                    </div> : 

                <Button style={{transform:"translateY(8px)"}} size="small" onClick={showDrawer} type="primary">Track Details</Button>
            }
            </div>


            <Row className="rounded bg-light py-2 px-3 my-1">
            <Col span={11} className="text-left">
                 <h6 className="m-0" >Item</h6>
               </Col>
               <Col span={4} className="text-left">
                 <h6 className="m-0" >Price</h6>
               </Col>
               <Col span={6} className="text-left">
                 <h6 className="m-0" >Qty</h6>
               </Col>
               <Col span={2} className="text-left">
                 <h6 className="m-0" >Total</h6>
               </Col>
               </Row>
            <div>
                {
                   order.productOrder.map((item, i)=>{
                   
                   return(
                    // return(<Row className="pb-2 my-2" style={{borderBottom:"1px solid #f5f5f5"}} key={i} justify="space-around"  align="middle">

                        <Row className="rounded pt-2 px-3 my-1" style={{borderBottom:"1px solid #f5f5f5"}} justify="space-around"  key={i}>
                            <Col span={10} className="text-left">
                            <h6 className="m-0" >{item.name}</h6>

                            </Col>
                            <Col span={4}>
                            <p>&#8377;{item.value}</p>
                            </Col>
                            <Col span={4}>
                            <p>  x {item.quantity}</p>
                            </Col>
                            <Col span={6}>
                                 <p className="text-right">&#8377;{item.quantity * item.value}</p>
                             </Col>
                            
                        </Row>
                        
                   )
                    })
                }
                  </div>
                <div className="pt-2">
                <div className="d-flex align-items-center justify-content-between px-3 ">
                        <p>SubTotal</p>
                        <p>&#8377;{ order.total}</p>
                    </div>

                    <div className="d-flex align-items-center justify-content-between px-3">
                        <p>Delivery</p>
                        <p>&#8377;{ order.delivery_charge}</p>
                    </div>

                    <div className="d-flex align-items-center justify-content-between px-3 ">
                        <h5>Total</h5>
                        <h5>&#8377;{ (order.total + order.delivery_charge)}</h5>
                    </div> 

                  

                    {   <div className="text-left px-3 pt-3 pb-5">
                            <h6>Delivery Address: </h6>
                            <p> {order.address.building_name}, {order.address.door_number}, {order.address.address}</p>
                        </div>
                        } 

                 </div>
                

            <Drawer
        title="Order Details"
        placement="bottom"
        closable={false}
        onClose={onClose}
        visible={visible}
        height={(order.orderStatus === 'Reject')? 200 : 360}
      >
      
<div>

<Tracking/>
</div>



      </Drawer>

{/* <a href="https://api.whatsapp.com/send?phone=8431787968">
<Avatar className="chatbtn bg-success"  size={45} icon={<FaWhatsapp 
 style={{transform:"translateY(-3px)", fontSize:"2rem"}}/>} /></a> */}


      <Drawer
        title="Chat with Vendor"
        placement="bottom"
        closable={false}
        onClose={onClose}
        visible={chatvisible}
        height={360}
      >
      
<div style={{zIndex:22}}>
{/* <WhatsAppWidget/> */}

{/* <Chat orderid={id}/> */}
</div>
      </Drawer>

            </>
            
            
            }
            
            <Footer/>  
  
        </OrderWrapProduct>
    )
}



const OrderWrapProduct = styled.div`
position:relative;
.chatbtn{

  position: fixed;
    right: -2%;
    bottom: 6%;
    transform:translate(-50%, -50%);
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);




}
.order {

background-color:#ffffff;
margin-bottom:1rem;
h6 {

    font-size:0.8rem;
}

}

` 
import React from 'react'
import styled from 'styled-components'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import keyUri from '../../key'
import {Link, useParams} from 'react-router-dom'


export default function Catagory({combo}) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: (combo && combo.length > 2) ? 3 : (combo && combo.length > 1) ? 2 : 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true
      };

    return (
        <ComboWrap  style={{width:"100%", overflowX:"hidden"}} className="mx-auto bg-light">
{ combo.length > 0 && <h6 className="p-2 mb-0">Combo Products</h6>
}
<Slider {...settings}>
    {
        combo && combo.map((item, i)=>{
          return <a key={i}  href={`/combo/${item._id}`}>
           <div  className="my-1" >
        <img src={keyUri.BACKEND_URI+`/combo-image/${item.combo_image}`} alt="fgfg" className="mx-auto d-block rounded-circle shadow-sm" height="60px" width="74%"/>       
        <p className="d-block text-center py-2">{item.combo_title}</p>
        </div> 
        </a>  
        })
    }
    </Slider>
        </ComboWrap>
    )
}
const ComboWrap = styled.div`

img {

width: 70px;
    height: 70px;
    padding: 0.5rem;

}
p{
    font-size:17px;
}
`
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import keyUri from '../../key'
import {Link, useParams} from 'react-router-dom'
import {useMedia} from 'react-use';
import axios from 'axios';
import key from '../../key';
import Loader from '../../shared/loader'

export default function Catagory() {

    const { id } = useParams()
const [catagories, setCategories] = useState([])
const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    axios.get(key.BACKEND_URI + `/current-store/${id}?category=category`).then(({data})=>{

        setCategories(data)
        setLoading(false)
    })

  }, [id])

    const isWide = useMedia('(min-width: 480px)');

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: isWide ? (( catagories.length > 7) ? 8 : ( catagories.length > 6) ? 7 : 
                        ( catagories.length > 5) ? 6:( catagories.length > 4) ? 5:
                        ( catagories.length > 3) ? 4:( catagories.length > 2) ? 3 :
                        ( catagories.length > 1) ? 2 : 1 ):
                    (( catagories.length > 2) ? 3 : ( catagories.length > 1) ? 2 : 1),
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true
      };


    return (
     
        <CatgoryWrap  style={{width:"100%", overflowX:"hidden"}} className="mx-auto">
            <br/>

            {   loading ? <Loader/> :    <>
<h6 className="p-2 mb-0">Shop by Catagory</h6>

<Slider {...settings}>
    {
       catagories.map((item, i)=>{
          return <Link key={i}  to={`/products/${item._id}`}>
           <div  className="my-3" >

    <img src={keyUri.BACKEND_URI+`/category-image/${item.category_image}`} alt="fgfg" style={{objectFit:'contain'}}
    className="mx-auto d-block rounded-circle shadow"/>       
    <p className="d-block text-center py-2" >{item.category_name}</p>

        </div> 
        </Link>  
        })
    }
    </Slider>
</>}
        </CatgoryWrap>

    )
}
const CatgoryWrap = styled.div`
img {
width: 70px;
    height: 70px;
    padding: 0.5rem;
}
p{
    font-size:17px;
}
`
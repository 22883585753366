import React from 'react'
import {Row, Col, Button} from 'antd'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import keyUri from '../../key'
import {StarFilled, ShareAltOutlined } from '@ant-design/icons';
import { Typography } from 'antd'
import axios from 'axios'
const {Paragraph} = Typography


export default function Stores({store}) {


    if(store.length < 1) {

     return   <h5 className="py-5 text-center text-secondary">No stores Available</h5>
    }

    const onClick = (e, item)=>{
        console.log(item)
        axios.get(keyUri.BACKEND_URI + `/update-views/${item._id}`)
    }

    return (
        <StoreWrap className="container my-3">

<div className="row">

        {

 store.map((item, i)=>{

  

    let ratings=  item.rating.reduce((total, current)=>{
        return total+current
    }, 0)

    let rate = (ratings/(item.rating.length )).toFixed(1)

             return <div key={i}   className="px-2 my-3 py-2 col-3 rounded">

            

        <Link to={`/store/${item._id}`} onClick={(e)=>onClick(e, item)} >

        <div className="card border-0 " style={{height:"340px",boxShadow:'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px'}}>
  <img className="bg-light"  style={{height:"170px", objectFit:"cover"}}
  src={keyUri.BACKEND_URI+`/store-image/${item.image}`}
   alt="Card image cap"/>

  <div className="card-body">
    <h5 className="card-title">{item.store_name}</h5>
    <p className="text-dark">{item.locality}</p>

                
                  </div>
                  <div className=" p-1 bg-light d-flex justify-content-between align-items-center">

        <div className="">
        <Paragraph copyable={{text:`https://piklocal-client-view.web.app/store/${item._id}`,
                        icon:<ShareAltOutlined  style={{color:"#01C0CF", transform:'translateY(5px)',  fontSize:"1.2rem"}}/>
                        }}/>
        </div>

        <div>
        <Button size="small"
         className="my-1 mx-2 shadow-sm px-2 py-0 rounded float-right" type="primary" style={{backgroundColor: rate>3.5 ? '#3e8e3e' : rate>2.5 ? '#e4c12a' : '#d83f3f' , border:'none'}} >
                                    <p><StarFilled style={{ transform:'translateY(-3.5px)'}}/><b>{rate}</b>  ({item.rating.length })</p> 
                            </Button>
        </div>



                  </div>


</div>

            </Link>   

             </div>


            }) 
        }
            </div>       
        </StoreWrap>
    )
}


const StoreWrap =  styled.div`

img{

    border-radius:5px;
}

hr{

    margin:0;

}

`



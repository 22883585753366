import React, { useEffect } from 'react'
import {storesSelector,  fetchOffer} from '../../../api/stores'
import Footer from '../../../global/footer'
import Loader from '../../../shared/loader'
import {useDispatch, useSelector} from 'react-redux'
import Offers from './offers'
import DOffers from './doffers'
import {useMedia} from 'react-use';

export default function Offerview() {
const { allOffers,  loading } = useSelector(storesSelector)

const isWide = useMedia('(min-width: 480px)');


const dispatch = useDispatch()
useEffect(()=>{
    dispatch(fetchOffer())
}, [dispatch])


    return (
        <div className="pb-5 container">
            <h4>Offers</h4>
            {
                loading ? <Loader/> : isWide?  <DOffers data={allOffers}/> : <Offers data={allOffers} />           
            }           
             <Footer/>  

        </div>
    )
}

import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../../shared/loader'
import Footer from '../../global/footer'
import { Input, Affix } from 'antd';
import axios from 'axios'
import {fetchNearStores, storesSelector} from '../../api/stores'
import Product from '../store/products/product'
import DProduct from '../store/products/dproducts'
import keyUri from '../../../src/key'
import { FaSearch } from 'react-icons/fa'
import { userAuthSelector} from '../../api/auth'
import {useMedia} from 'react-use';
import { useDebounce } from "use-debounce";


export default function SearchProduct() {
    const { near_stores } = useSelector(storesSelector)
 const [products, setProducts] = useState([])
 const [setvalue, setSearch] = useState(false)
    const [load, setLoader] = useState(false)
 const { user } = useSelector(userAuthSelector)
const dispatch = useDispatch();
const isWide = useMedia('(min-width: 480px)');
const [border, borderState] = useState(false)
     const [word, searchWord] = useState()

const [debouncedText] = useDebounce(word, 2000);

useEffect(()=>{
  
  user && dispatch(fetchNearStores(user.address))

  if(debouncedText){
    filtersearch(debouncedText) 
  }
  else{
    
  }

  
}, [dispatch, user, debouncedText])




const filtersearch = (searchword) =>{

      axios.post(keyUri.BACKEND_URI + `/products?search=${searchword}`, {store:near_stores} ).then(({data})=>{
       
      setProducts(data.product)
   setLoader(false)
   setSearch(false)

  })
  

}


 const onSearch = (e) => {
  setLoader(true)
  setSearch(true)
 
  searchWord(e.target.value)

  };




    return (
        <div className="pt-5 pb-5 container">
                <Affix offsetTop={3.5} onChange={affixed => borderState(!border) }>

          <div className="px-2">
            <Input className="py-1" prefix={<FaSearch/>}
             style={{ border: border? "none" : "", outline: border? "white" : "", borderRadius:"0.4rem", color:"#3498DB"}}
             allowClear 
             placeholder="Search Products"
             onChange={onSearch}
             
             enterButton="Search" />
             
           </div>
            </Affix>
           <div className="px-2 ">
                {                  
                    load ? <Loader/> : isWide ? 
                    <DProduct search={true} start_search={setvalue} products={products}/> :

                    <Product loader={load} start_search={setvalue} products={products}/>              
                }
            </div>
            <Footer/>  
        </div>
       
    )
}

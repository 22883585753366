import React, {useEffect} from 'react'
import { Form, Input, Button, InputNumber, Switch } from 'antd';
import {updateUser, userAuthSelector,fetchUserDetails} from '../../../api/auth'
import {useDispatch,useSelector } from 'react-redux'
import {useParams} from 'react-router-dom'
import { UploadOutlined } from '@ant-design/icons';

import Footer from '../../../global/footer'


export default function EditProfile() {
  
  const dispatch = useDispatch()
  const {user, loading} = useSelector(userAuthSelector)
  console.log(user)
  
  const [form] = Form.useForm();
  const {id} = useParams()
  

  console.log(id);
    
      useEffect(()=>{
  
        user &&  form.setFieldsValue({
            user_name:user.user_name,
            phone_number:user.phone_number,
    
            });
  
      }, [])

      const layout = {
        labelCol: {
          span: 4,
        },
        wrapperCol: {
          span: 10,
        },
      };
    
      const tailLayout = {
        wrapperCol: {
          offset: 0,
          span: 6,
        },
      };
  

      const onFinish = (values) => {
        const userdata = {

          user_name:values.user_name,
          phone_number:values.phone_number     
          
        }
        dispatch(updateUser(userdata, id))
        window.location.reload()

        };


        const onFinishFailed = (errorInfo) => {
          console.log('Failed:', errorInfo);
        };



    return (

<>
       
{
    <>
    <div className="mx-4 ">
            <h6 className="mt-5">Edit Profile</h6>

   <Form
    {...layout}
    form={form}
    name="basic"
    initialValues={{ remember: true }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
  >


<Form.Item
        label="Enter Name"
        name="user_name"
        rules={[{ required: true, message: 'Please input your username!' }]}
      >

        <Input />
      </Form.Item> 

      
  
   
<Form.Item
        label="Phone Number"
        name="phone_number"
        rules={[{ required: true, message: 'Please input your phone number!' }]}
        >

<Input/>

</Form.Item>

<Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>

    </Form>
 
    </div>

   
</>
}
<Footer/>  

        </>
    ) 
    }

    

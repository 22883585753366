import React, { useEffect, useCallback,  useState } from 'react'
import {storesSelector, fetchOneStore} from '../../../api/stores'
import {userAuthSelector, fetchUserDetails} from '../../../api/auth'
import {cartCouponSelector} from '../../../api/cart'
import { orderSelector } from '../../../api/placeOrder'
import Footer from '../../../global/footer'
import Cart from './cart'
import {useDispatch, useSelector} from 'react-redux'
import { Button, message } from 'antd'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import distance from '../../../shared/distanceMeasure'
import {useQuery} from '../../../shared/useQueary'

export default function Cartview({history}) {
const {userEnterStore, user} = useSelector(userAuthSelector)
const {current_store } = useSelector(storesSelector)
const {cartCoupons, total } = useSelector(cartCouponSelector)
const {isOrderPlaced, order } = useSelector(orderSelector)

const [deliveryCharge, setDeliveryCharge] = useState(0)

const id = useQuery('id')
const dispatch = useDispatch()


useEffect(()=>{
    userEnterStore &&  dispatch(fetchOneStore(userEnterStore._id))
}, [dispatch, isOrderPlaced])

useEffect(()=>{
    dispatch(fetchOneStore(id))
}, [dispatch])


const dChargefun = () => {


    if(user.address && current_store) {

        const dist = distance(user.address.lat, user.address.lng, current_store.loc.coordinates[1], current_store.loc.coordinates[0], 'K').toFixed(2)
    

        if(current_store.deliveryCharges) {
    
            const delivercharge = (dist < 1)? current_store.deliveryCharges.above_one 
    
            : (dist < 2)? current_store.deliveryCharges.above_two : current_store.deliveryCharges.above_three 
    
         return  delivercharge
    
        }
    
    
    }
}






const freeDelivery = () => {
    if(current_store && current_store.deliveryCharges) { 
        if( (current_store.minOrder > 0) && (current_store.deliveryCharges.above_one === 0) 
        && (current_store.deliveryCharges.above_two === 0)
         && (current_store.deliveryCharges.above_three === 0)) {
        return true
        }
        else {
            return false
        }
    }
}



    return (
        <CartWrap>
           

            <Cart {...current_store} dCharge={dChargefun}  freeDelivery={freeDelivery} cart={cartCoupons} user={user} total={total}/>
           
        
            <Footer/>  

        </CartWrap>
    )
}


const CartWrap = styled.div`

padding-top: 35px;


`
import React, { useEffect, useState } from 'react'
import Logo from '../images/logo.png'
import { FaBeer } from 'react-icons/fa';
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import {Link, useHistory, useLocation} from 'react-router-dom'
import { ArrowLeftOutlined } from '@ant-design/icons';

export default function Navbar({onClick, open}) {

    const {goBack} = useHistory()

const [state, setState] = useState(false)
    const {pathname} = useLocation()

    useEffect(()=>{

if(pathname === '/' || pathname === '/policy' ||pathname === '/account' ||
pathname === '/search' ||pathname === '/offers' ||pathname === '/cart' || pathname === '/login' ){


    setState(true)

} else {

     setState(false)
}

    })


    return (
        <NavbarWrap className="container-fluid px-3 py-1">
            <div className="row align-items-center">
            <div className="col-4">
      { !state && <Fade left duration={100}> <ArrowLeftOutlined id="arrow" 
      onClick = {()=>goBack()} color="primary"/> </Fade> }
     { state && !open &&  <Fade left duration={100}>
                 <div className="icon" onClick={onClick} >
                  <div></div>
                  <div></div>
                   <div></div>
               

              </div></Fade>  
                }
             
            </div>
            <div className="col-4">
                <img src={Logo} className="d-block mx-auto" width="90px" alt="logo"/>
                
             
            </div>
            <div className="col-4"></div>
            </div>

        </NavbarWrap>
    )
}


const NavbarWrap = styled.div`

position: fixed;
top:0;
width:100%;
background-color: #ffffff;
box-shadow: 0 1px 2px 1px #d0d0d0;
padding-bottom : 2px;
z-index: 1;
    
#arrow {

color:#11C4D2;
font-size:1.3rem;
transform:translateX(-2.4rem);

}

    .icon{

        width:25px;
        cursor: pointer;

        div{

            list-style:none;
            height:2px;
            background-color: #06c1d0;
            margin:4px 0;



        }
    }
    `
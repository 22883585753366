import React, { useState, useEffect } from 'react'
import {GoogleMap, withScriptjs, InfoWindow,  withGoogleMap, Marker} from 'react-google-maps'
import Geocode from "react-geocode";
import { Button, Drawer, Form, Input, message } from 'antd';
import Loader from './loader'
import { GoLocation } from 'react-icons/go';
import {addUserAddress,changeUserAddress, userAuthSelector, fetchUserDetails} from '../api/auth'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {storesSelector, fetchOneStore} from '../api/stores'
import distance from '../shared/distanceMeasure'
import {useHistory} from 'react-router-dom'

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';



const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 1,
      span: 1,
    },
  };




 function Map() {

  Geocode.setApiKey("AIzaSyA6Xusz8px2ixZEGkxK7JT7Y41A5FLyUM0");
  Geocode.setLanguage("en");
  const [dvisible, setDVisible] = useState(true);
  const {userEnterStore, user} = useSelector(userAuthSelector)
  const _id = localStorage.getItem('_id')
  const {current_store } = useSelector(storesSelector)
   const [pos, setPos] =  useState({ lat: 12.9608386, lng: 77.4928792 })
   const [visible, setVisible] =  useState(false)
   const [curentaddress, setCAdress] = useState('')
   const [loading, setLoading] = useState(false)
  const [address, setAddress] = useState("");
  const { goBack } = useHistory()


  // console.log(pos);
  // console.log(address);
  // console.log(curentaddress);


  const handleSelect = async value => {
    console.log('test');
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);

    setAddress(value);
    getAddress(latLng.lat, latLng.lng )
    setPos({lat:latLng.lat, lng:latLng.lng})
  };

   

   const getAddress = (lat, lng) =>{
    console.log('test2');

    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        console.log(response);
        const address = response.results[0].formatted_address;
        setCAdress(address)
      },
      (error) => {
        console.log('error');
        console.error(error);
      }
    );

   }

   const dispatch = useDispatch()
   // const {user, phone} = useSelector(userAuthSelector)
   let isChange = useLocation()
   
   
   
   useEffect(()=>{
  // userdata &&  setUser(userdata)


if(user) {

    if(isChange.search && user.address ) {
    getAddress(user.address.lat, user.address.lng)
  } 

  if(!isChange.search && user.address){
    
    dispatch(fetchOneStore(userEnterStore))
    navigator.geolocation.getCurrentPosition((position)=> {
        getAddress(position.coords.latitude , position.coords.longitude)
       
          })

}
}
  
if(!isChange.search) {

navigator.geolocation.getCurrentPosition((position)=> {
  getAddress(position.coords.latitude , position.coords.longitude)
 
    })

}


  fetchUserDetails( user && user._id)
        setVisible(true)
  
}, [])






const onFinish = (values) => {


  let currentData = {

building_name:values.building_name,
door_number:values.door_number,
latlng: {lat:pos.lat, lng:pos.lng},
delivery_address:curentaddress,
// phone_number:phone
    
  }
  
  
  if(isChange.search && current_store){
    const dist = distance(currentData.latlng.lat, currentData.latlng.lng, current_store.loc.coordinates[1], current_store.loc.coordinates[0], 'K').toFixed(2)

        if(dist > (current_store.distance/1000))
              {
                  return message.info('Delivery not available for this address')  
              }  

        else{
         
          dispatch(changeUserAddress(currentData, user))
          return  goBack()
        }
  }
  
    dispatch(addUserAddress(currentData, user._id))
    return  goBack()

  };



  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };




    const handleClick = (e) =>{
      console.log('test3');
      console.log(e.latLng.lat());
      console.log(e.latLng.lng());

      setLoading(true)

        setPos({lat:e.latLng.lat(), lng:e.latLng.lng()})

        getAddress(e.latLng.lat(), e.latLng.lng())
        setAddress('')
        setTimeout(()=>setLoading(false), 500)
        showDrawer()

        setVisible(true)
 
    }


    const showDrawer = () => {
        setDVisible(true);
      };
      const onClose = () => {
        setDVisible(false);
      };


    return (
        <>
        <GoogleMap
        defaultZoom={18}
        defaultCenter={pos}
        onClick={handleClick}
      >
        <Marker
        

        visible={visible}
          position={pos}
          draggable
          onDragEnd={handleClick}
          onDragStart={()=>setLoading(true)}
          title="Delivery Address"
        >

<InfoWindow >
        <div>
     
          {" "}
          <p style={{margin:"1px"}}> {isChange.search ?  "Your Delivery Address" : "Your Current Address"}</p>
     
        {/* <p style={{marginTop:"10px"}}>
          
          { loading? 'loading...' : curentaddress}</p> */}

        </div>
      </InfoWindow>
          </Marker>
      </GoogleMap>

      <Drawer

          bodyStyle={{padding:"0"}}
          placement="bottom"
          closable={false}
          onClose={onClose}
          height={280}
          visible={dvisible}
        >

<PlacesAutocomplete
      value={address}
      onChange={setAddress}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="pt-3 pb-2 px-3">
  
          <Input style={{ width: '95%' }} {...getInputProps({ placeholder: "Type address" })} />
          
          <div>
            {loading ? <div>...loading</div> : null}

            {suggestions.map(suggestion => {
              const style = {
                backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
              

              };

              return (
                <div  {...getSuggestionItemProps(suggestion, { style })}>
                  <p 
          className="text-info suggest  bg-light mb-1" >{suggestion.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>

  <div style={{ width:'92%', backgroundColor: "#f5fafb"}} className="shadow-sm px-2 py-2 mb-3 mx-3"> 

 <p className="text-secondary m-0">{ loading? <Loader/> :
  <GoLocation className="text-danger"/> } {curentaddress}</p>

</div>  

<div className="px-3 py-0">

 <Form
   
      name="basic"
      
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >

<Form.Item  className="pb-2" >

      <Input.Group compact>


      <Form.Item
        name="building_name"
        
    
      >
        <Input  placeholder="Building name" />
      </Form.Item>

      <Form.Item
             className="ml-3"

        name="door_number"

      >
        <Input style={{width:"120px"}}  placeholder="Door number"/>
      </Form.Item>

    </Input.Group>
     
</Form.Item>


      <Form.Item {...tailLayout} style={{transform:"translateY(-2rem)" , paddingLeft:'60px'}}>
        <Button type="primary" htmlType="submit"> Confirm </Button>
        <Button onClick={onClose} className="ml-3" danger type="primary"> Change </Button>
      </Form.Item>
    </Form>

    </div>

        </Drawer>

     </>
    )
}

const WrappedMap = withScriptjs(withGoogleMap(Map));
// const WrappedMap = null


export default function Gmap() {



    return (
        <div style={{width:"100%", height:"100%"}}>
            
            <WrappedMap
             googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA6Xusz8px2ixZEGkxK7JT7Y41A5FLyUM0&v=3.exp&libraries=geometry,drawing,places"
             loadingElement={<div style={{ height: `100%` }} />}
             containerElement={<div style={{ height: `100vh` }} />}
             mapElement={<div style={{ height: `100%` }} />} 
             />



        </div>
    )
}


import React,{useState, useRef , useEffect} from 'react'
import { Button, Carousel, Drawer, Checkbox, Form,Input, message} from 'antd';

import {fetchUserPhoneAuth, userAuthSelector, fetchSendotp, fetchUserlogin, addUserPassword} from '../../api/auth'
import { useDispatch, useSelector } from 'react-redux';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Loader from '../../shared/loader'
import {useMedia} from 'react-use';
import bannerimg1 from '../../images/login1.png'
import bannerimg2 from '../../images/login2.png'
import { Link} from 'react-router-dom'

const contentStyle = {
  height: '72vh',

};


export default function Intialpage({history, location}) {
  const isWide = useMedia('(min-width: 480px)');
  const slider = useRef();

  const [code, setCode] = useState(false)
  const [phone, setPhone] = useState(null)
  const [otpSent, setOtpSent] = useState(null)
const [isOtpMatch, setIsOtpMatch] = useState(false)

    const [visible, setVisible] = useState(false);
    const [loginVisible, setLoginVisible] = useState(false);

const {user, loading, hasError} = useSelector(userAuthSelector)


    useEffect(()=>{



      if(user && !loading) {

     return  history.push(`/`)
      }

    })


    const showDrawer = () => {
      setVisible(true);
    };

    
    const showLoginDrawer = () => {
      setLoginVisible(true);
    };

    const onClose = () => {
      setVisible(false);
      setLoginVisible(false)
    };

    const dispatch = useDispatch()




    const onLoginFinish = values => {
    
    dispatch(fetchUserlogin(values))   
    }
  


  const onFinish = values => {

      if(isOtpMatch && code){
            const data = {
              user_name: values.name,
              password: values.password,
              phone : phone,
            }
              dispatch(addUserPassword(data))   
              return history.push('/google-map-address')
      }
      
      if(!code && !isOtpMatch){

          let otpCreated =   Math.floor(Math.random() * (100000-999999) + 999999);
            const data = {
              otp: otpCreated,
              phone : values.phone,
            }
            setOtpSent(otpCreated)
            setPhone(values.phone)
            setCode(true)

        return dispatch(fetchSendotp(data) ) 
    
      }
      if (code) {
            if(values.otp == otpSent){
                setIsOtpMatch(true)
                message.info('Phone number verified')
              }
              else{
                message.error('Invalid otp')
                setIsOtpMatch(false)   
        
            }
      }
    }

    const [checked, setChecked] = useState(false)

    const  onChange = (e) => {

      setChecked(!checked)

    }


   
    return (
      <>
      {

        loading? <Loader/> : 
     
        <div style={{transform:"translateY(-5px)", padding:isWide? "5rem" : "0"}} >

          <div className="row ">
            <div className="col-sm-6 col-12 p-0 ">
          

            
            <Carousel  ref={ref => { 
              slider.current = ref;
            }}
            autoplaySpeed={4000} speed={1000} autoplay    dots={false}>

<div className=" d-flex align-items-center justify-content-center">
          <img style={contentStyle} src={bannerimg1} alt="d" width="100%"/>
    </div>
    <div className="d-flex align-items-center justify-content-center">
    <img style={contentStyle}src={bannerimg2} alt="d" width="100%"/>l
    </div>


  </Carousel>
  </div>
<div className="col-sm-6 col-12 p-0 " >
 

  <div className="mt-4" style={{transform:isWide? "translateY(10rem)" : ''}}>

<h5>Order From Your Local Stores</h5>

<Button type="primary" onClick={showDrawer} >Register</Button>

<p className="my-3">Have an account? <b onClick={showLoginDrawer} style={{color:'green', cursor:"pointer"}}>Login</b></p>

  </div>

{/* login */}
  <Drawer
        
        bodyStyle={{padding:"0.3rem"}}
        placement={isWide? "right": "bottom"}
           closable={false}
           onClose={onClose}
           height={ 300}
           width = {isWide ? "30%" : "100%"}
           visible={loginVisible}
         >
             <div className="d-flex align-items-center justify-content-center pt-2 ">
              <h5 >Login</h5>
             </div>
      <hr style={{height:"0.2rem"}} className="my-2 bg-light border-0"/>

<div className="d-flex align-items-center justify-content-center pt-3">
<div>
<Form

width="100%"
    name="normal_login"
    className="login-form"
    initialValues={{ remember: true }}
    onFinish={onLoginFinish}
  >

<Form.Item
      name="phone_number"
      rules={[{ required: true, message: 'Enter your phone number' },
      {min: 10, message:"Mininum 10 number"},
      {max:10, message:"Maximum 10 number"},
      {pattern:"[0-9]", message:"Only Numbers"}
      ]}
    >
      <Input style={{ width: '100%' }} prefix={'+91 ' } placeholder="Phone number" />
    </Form.Item>

    <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
        style={{marginBottom:'0px'}}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />

      </Form.Item>
          <Link to="/forgot-password">
            <small className="m-0 p-0 pt-1 w-1 float-right" sty>Forgot password ?</small>
          </Link>

    <Button type="primary"  htmlType="submit" className="login-form-button d-block w-100 mx-auto mt-5">
        LOGIN
      </Button>
     
</Form>

</div>
</div>
 </Drawer>


 {/* Register */}
  <Drawer
        
     bodyStyle={{padding:"0.3rem"}}
        placement={isWide? "right": "bottom"}
        closable={false}
        onClose={onClose}
        height={300}
        width = {isWide ? "30%" : "100%"}

        visible={visible}
      >
        { !code && !isOtpMatch ?
  <>

        <div className="d-flex align-items-center justify-content-center  pt-2 ">
        <h5 >Register</h5>
      </div>

      <hr style={{height:"0.2rem"}} className="my-2 bg-light border-0"/>

  <div className="d-flex align-items-center justify-content-center pt-4">
  <div>
  <h6  className="mb-2">Enter your phone number </h6>

  <Form


      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >

<Form.Item
        name="phone"
        rules={[{ required: true, message: 'Enter your phone number' },
        {min: 10, message:"Mininum 10 number"},
        {max:10, message:"Maximum 10 number"},
        {pattern:"[0-9]", message:"Only Numbers"}
        ]}
      >
        <Input style={{ width: '100%' }} prefix={'+91' } placeholder="Phone number" />
      </Form.Item >
     
<Checkbox style={{transform:'translateY(-0.5rem)'}} value={checked} onChange={onChange}>
  <small>By registering you agree to terms and conditions</small></Checkbox>    
     
      <Button type="primary" disabled={!checked}  htmlType="submit" className="login-form-button d-block mx-auto w-100">
          VERIFY
        </Button>
       
</Form>

</div>
</div>

  </> : !isOtpMatch && code ? <> 
  <div className="d-flex align-items-center justify-content-center  pt-2 ">
        <h6 >Verify your phone number</h6>
      </div>

      <hr style={{height:"0.2rem"}} className="my-2 bg-light border-0"/>

  <div className="d-flex align-items-center justify-content-center pt-2">
  <div>
  

  <p >Enter the 6-digit otp we sent to <br></br> +91 {phone}</p>

  <Form

      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >

      <Form.Item
        name="otp"
        rules={[{ required: true, min:6, max:6, message: 'Enter 6-digit code ' }  ]}
      >
        <Input style={{ width: '100%' }}  placeholder="6-digit code" />
      </Form.Item>

      <Button type="primary"  htmlType="submit" className="login-form-button ml-5">
         Continue
        </Button>
       
</Form>


</div>
</div>
  </> : 
    <> 
   <div className="d-flex align-items-center justify-content-center  pt-1 ">
         <h5 >Register</h5>
       </div>
 
       <hr style={{height:"0.2rem"}} className="my-1 bg-light border-0"/>
 
   <div className="d-flex align-items-center justify-content-center pt-4  pb-3">
   <div> 
 
   <Form
 
       name="normal_login"
       className="login-form"
       initialValues={{ remember: true }}
       onFinish={onFinish}
     >
 
 <Form.Item
            
              name="name"
              // style={{paddingTop:'10px'}}
              rules={[{ required: true, message: 'Enter Name' },
              {pattern:"[a-z,A-Z]", message:"Only characters"}
            ]}
            >
              <Input  placeholder="Enter your name"/>
            </Form.Item>

 
            <Form.Item
            name='password' 
           
            rules={[
              {
                required: true,
                message: 'Please enter password!',
              }, {min: 8,  message: 'Minimun 8 characters'}
            ]}
            hasFeedback
          >
            <Input.Password style={{ width: '100%' }}  placeholder="password" />
            
          </Form.Item>

      <Form.Item
            name="confirm"
           
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!', 
              }, 
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
    
                  return Promise.reject('Password did not match!');
                },
              }),
            ]}
          >
            <Input.Password  placeholder="confirm" />
          </Form.Item> 

{/* </div> */}
          {/* </Input.Group> */}
      {/* </Form.Item> */}
 
      
       <Button type="primary"  htmlType="submit" className="login-form-button w-100 d-block mx-auto">
          Login
         </Button>
        
  </Form>
  
  </div>
 </div>
   </>

        }

      </Drawer>

      </div>
      </div>
        </div>
         }
        </>
    )
}



import React,{useState,useRef, useEffect} from 'react'
import moment from 'moment'
import {Row, Col, Drawer, Image,Radio, Space, Button ,Form ,message} from 'antd'
import Fade from 'react-reveal/Fade'
import styled from 'styled-components'
import {cartCouponSelector, removeItem, qtyupdate } from '../../../api/cart'
import {fetchOrder, orderSelector , createOrder} from '../../../api/placeOrder'
import {userAuthSelector} from '../../../api/auth'
import {  useDispatch, useSelector } from 'react-redux';
import keyUri from '../../../key'
import loadingImage from '../../../images/load2.gif'
import emptyCartImage from '../../../images/emptycart.png'
import { Link } from 'react-router-dom'
import { GoLocation } from 'react-icons/go';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {CopyOutlined } from '@ant-design/icons';


export default function Cart({store_name, user, dCharge,freeDelivery, upi,  delivery, store_address, minOrder, cart, total, excahange }) {



    const [visible, setVisible] = useState(false);
    const [orderStatus, setOrderStatus] = useState(false)
    const [copied, setCopied] = useState(false)

    const textAreaRef = useRef(null);



    const showDrawer = () => {
        setVisible(true);
      };
    
      const onClose = () => {
        setVisible(false);
      };

    //   user &&  console.log(user.address.defaultAddress);

const {cartCoupons} = useSelector(cartCouponSelector)
// const {isOrderPlaced} = useSelector(orderSelector)
const {isUserAuthenticate} = useSelector(userAuthSelector)

const [cartData, setCartData] = useState(false);

let cartProducts = localStorage.getItem('cart') ? JSON.parse( localStorage.getItem('cart') )  : []

const [isTakeAway,setIsTakeAway]=useState('takeaway')

const [form] = Form.useForm();



const deliveryCharge = isTakeAway === 'homedelivery' && (minOrder > total) ?  dCharge() : 0 

 

    const dispatch = useDispatch()
 

    useEffect(() => {
      if(cartProducts.length > 0){
          cartProducts && setCartData(true)
      }
      
      }, [dispatch])
      

      const increment = (id,item) =>{
      
          let data = {id, incr:'incr'}

                  dispatch(qtyupdate(data))
          
        
        }
        
        const decrement = (id, item) =>{
        
          let data = {id, decr:'decr'}


         if(item.units === 1){


            dispatch(removeItem(id))
         }

          dispatch(qtyupdate(data))
                
        }

        const clearCart = () => {    
            localStorage.removeItem('cart')
                window.location.reload()
          };


          const handleChange = e=> {
            setIsTakeAway(e.target.value)
            
          };


    return (
      <>
      {user && 
        <CartWrap>
            {


cartData &&  <div className=" d-flex justify-content-between align-items-center p-2 text-left">
               
                 <div>
                <h6 className="m-0">{store_name}</h6>
                <small>Address: &nbsp;{store_address}</small>
                </div>

                <div>
                <small >Date:&nbsp;{moment().format('MMM Do YYYY')}</small><br/>
                </div>
            </div>

            
}
           {
               cart.length === 0 ? <div> <img src={emptyCartImage} className="mx-auto d-block" alt="empty" width="80%" />
               
               <h6>Cart is Empty!</h6>
               </div> : 
               
               <div>
{/* 
                  <div className=" d-flex justify-content-between align-items-start pl-3 text-left">
                <h6 style={{color:'white', backgroundColor:'#7ebfea'}} onClick={()=>clearCart()} > Clear Cart </h6>               
                </div> */}

                   {

                       cart.map((item, i)=>{


                         return(<Row className="pb-2 my-2" style={{borderBottom:"1px solid #f5f5f5"}} key={i} justify="space-around" align="middle">
                         
                         <Col span={8}>
                           
         <Fade>
        <Space size={0} >
 <button className="add" style={{borderTopLeftRadius:"4px", borderBottomLeftRadius:"4px"}}  disabled={item.units === 0 ? true : false} type="primary" onClick={()=>decrement(item._id, item)}>-</button>
 <button className="qty" >{item.units}</button>
 <button className="add" style={{borderTopRightRadius:"4px", borderBottomRightRadius:"4px"}}  type="primary" onClick={()=>increment(item._id, item) }>+</button>

</Space>
</Fade>

                 
                         </Col>
                         <Col span={10}>
                        <Row align="middle">
                            <Col span={12}>
                            <Image
        height="100%"
        width={40}
        src={keyUri.BACKEND_URI+`/product-image/${item.image}`}
        placeholder={
          <Image
            preview={false}
            src={loadingImage}
            width={50}
            height="100%"
                      />
        }
      />
                            </Col >
                            <Col span={12} className="text-left">
                            <p className="m-0" >{item.product_name}</p>
                            <small>{item.product_description}</small>
                            </Col>

                        </Row>
                         

                         </Col>
                         <Col span={4}>

                <h6 className="text-right">&#8377;{item.units* item.sale_price}</h6>

                            </Col>
                <Col span={2}>

                {/* <Button onClick={()=>dispatch(removeItem(item._id))}>X</Button> */}

                            </Col>
                                              
                         
                         </Row>)

                       })
                   }

                      <div className="d-flex align-items-center justify-content-between px-4">
                       <h6>SubTotal</h6>
                       <h6>&#8377;{total}/-</h6>
                   </div> 

                  <div className="d-flex align-items-center justify-content-between px-4">
                       <h6>Delivery charges</h6>
                       <h6>  { isTakeAway === 'homedelivery' && (minOrder > total) ? `₹${dCharge()}/-` : "₹0/-" }</h6>
                   </div>

                 

                   <div className="d-flex align-items-center justify-content-between px-4 " >
                       <div>
                           <h6 >Total</h6>
                           <p></p>
                       </div>
                
                       <h6 className="text-success">&#8377;{ isTakeAway === 'homedelivery' && (minOrder > total) ? (total + dCharge()) : total }/-</h6>     
                                                       
                   </div> 

                   <div className="d-flex align-items-center justify-content-between px-4" >
                       <div>
                           <p>Delivery Available :<b>  {delivery===true? 'Yes' : 'No'}  </b></p>
                           <p>Exchange Accept : <b> {excahange===true? 'Yes': 'No'} </b></p>
                           
                       </div>
                                                       
                   </div> 
                   <div style={{paddingBottom:'130px'}}>

               {  upi && <div className="d-flex align-items-start px-4 ">
                <p> UPI ID : <b> {upi} </b>

                <CopyToClipboard text={upi}
              onCopy={() => {setCopied(true) 
                message.info('Copied to clipboard')  }}>
              <Button className='px-2' size="small"> Copy <CopyOutlined /></Button>
            </CopyToClipboard> </p>

                </div> }
                
                   <div className="d-flex align-items-center justify-content-between px-4" >
                     <Radio.Group onChange={handleChange} value={isTakeAway} >
                      <Radio value="takeaway">Take away</Radio>
                      {delivery===true ?   freeDelivery() && (minOrder > total)  ?  '' :
                      <Radio value="homedelivery" >Home delivery</Radio> : ''
                     }
                      </Radio.Group>
                     </div> 

                

                   </div>


                   <div style={{paddingTop:'20px', width:"100%", position:'fixed' , bottom:'7.6%',}}  >

                       {
                      user?<Button className="block mx-auto" style={{ width:'100%',height:'35px', color: "white",background:"#4ba04b"}} type="ghost" onClick={showDrawer}   >
                            
                           <p className="m-0" style={{color:"white", fontWeight:"500"}}>SET DELIVERY ADDRESS </p>    
                            </Button > : <Button type='primary' style={{  width:'360px'}}>
                            
                            
                                <Link to='/login'>Login</Link>
                            </Button>

                       }
                        
                   </div>
                  
               </div>
           }
            

            <Drawer
        title="Your Delivery Address"
        placement="bottom"
        closable={false}
        onClose={onClose}
        visible={visible}
        height={275}
      >
     {user.address  && <p>Building Name :&nbsp; <b>{user.address.building_name}</b> &nbsp; &nbsp; &nbsp; Door No : <b className="ml-1">{user.address.door_number}</b></p>}

     {user.address  && <p><GoLocation
     style={{fontWeight:"bold", fontSize:"1.5rem"}}
     className="text-danger px-1"/>{user.address.defaultAddress}</p>}



<Link to="/google-map-address?ischange=true">
     <Button className="ml-2 mb-3" 
     style={{  color: "white" }}
     size="small" type="primary" >Change Address</Button>
</Link>


<div className="d-flex justify-content-center">
     <Button 
     disabled={orderStatus ? true : false}
     style={{  width:'300px' , color: "white",background: !orderStatus ? "#4ba04b" : 'rgb(115 167 115)'}} type="ghost" 
        onClick={()=>{
            dispatch(createOrder(cartCoupons, user.phone_number ,isTakeAway, deliveryCharge ))
            setOrderStatus(true)
            }}>
                            
                  Place Order    
     </Button >

    </div>

      </Drawer>
        </CartWrap>
}
</>
        
    )
}


const CartWrap =  styled.div`

/* padding-bottom: 55px; */


.add, .qty {

     padding: 2px 12px;
    background-color:#AFD6F0;
    color:white;
    border:none;
    
   
    font-size:12px;
    font-weight:600;

 &:focus {

  outline: none;

 }   
}

.qty {

    background-color:#3498DB;
    color:white;
    border-radius:0
 
}



`